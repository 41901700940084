import React, { Component } from "react";
import {
    addImages,
    getMember,
    updateMember,
} from "../../../functions/postFunctions";

import { CustomButton, LinkButton } from "../../../components/Buttons/Button";
import { CustomInput } from "../../../components/Form/Inputs/Inputs";
import DatePicker from "react-date-picker";
import { addMemberValidation } from "../../../functions/validateFunctions";
import { dateLocalisationShort } from "../../../functions/functions";
import { Constants } from "../../../services/constants.service";

class EditMember extends Component {
    state = {
        name: "",
        address: "",
        city: "",
        authorized_person: "",
        phone: "",
        mail: "",
        web: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        logo: null,
        report: null,
        creation_date: undefined,
        errors: [],
        showErrorPopup: false,
        showWarningPopup: false,
        listOfImages: [],
        edit: false,
        completed: 0,
        showUploadPopup: false,
        uploadComplete: false,
        rightSide: false,
        categoryList: [],
        category_id: 4,
        activeLang: 1,
        uploadedImages: [],
        available_times_from: [],
        available_times_to: [],
        loading: true,
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Dodaj člana");

        getMember(this.props.match.params.id).then((res) => {
            if (res.success) {
                this.setState({ ...res.member, loading: false });
            } else {
                window.location = `/${this.props.customer}`;
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.errors != this.state.errors &&
            this.state.errors.length > 0
        ) {
            this.setState({ showErrorPopup: true });
        }
        if (prevState.stepOne !== this.state.stepOne) {
            const item = document.querySelector(".gallery");
            this.navigationMove(item, "gallery");
        }
        if (prevState.time_from !== this.state.time_from) {
            this.handleTimeChange(this.state.time_from);
        }
    }

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleFileInput = (event, name) => {
        this.setState({ [name]: event[0] });
    };

    removePopup = () => {
        this.setState({
            showErrorPopup: false,
            showWarningPopup: false,
            showUploadPopup: false,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            name,
            address,
            city,
            authorized_person,
            phone,
            mail,
            web,
            facebook,
            instagram,
            linkedin,
            logo,
            report,
            creation_date,
        } = this.state;
        const data = {
            name,
            address,
            city,
            authorized_person,
            phone,
            mail,
            web,
            facebook,
            instagram,
            linkedin,
            logo,
            report,
            creation_date,
        };
        const errors = addMemberValidation(data);

        if (errors.length === 0) {
            this.createPage(data);
        } else {
            this.setState({ errors });
        }
    };

    handleAddImages = () => {
        const { logo } = this.state;
        const data = new FormData();
        if (logo.length > 0) {
            logo.forEach((e) => {
                data.append("logo[]", e);
            });
        }
        data.append("page_id", 0);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        addImages(data, this.props.token, config).then((res) => {
            if (res.success) {
                this.setState(({ uploadedImages }) => ({
                    uploadedImages: [...uploadedImages, ...res.images],
                    images: null,
                }));
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    createPage = (data) => {
        const formData = new FormData();
        const creation_date = data.creation_date;
        data.creation_date = dateLocalisationShort(
            creation_date,
            "en-GB"
        ).replaceAll("/", ".");
        data.id = this.state.id;
        Object.keys(data).forEach((e) => {
            formData.append(e, data[e]);
        });
        this.setState({ showUploadPopup: true });
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };

        updateMember(formData, this.props.token, config).then((res) => {
            if (res.success) {
                if (!this.props.customer) {
                    this.props.history.push("/clanovi");
                } else {
                    this.setState({ showUploadPopup: false });
                }
            } else {
                this.setState({ showErrorPopup: true, errors: res.errors });
            }
        });
    };

    renderForm = (num) => {
        const server = Constants.SERVER;

        return (
            <div className="langWrapper">
                <div className="row mt-50">
                    <div className="col-lg-3">
                        <CustomInput
                            label="Naziv"
                            value={this.state.name}
                            handleChange={this.handleInput}
                            name="name"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-3">
                        <CustomInput
                            label="Adresa"
                            value={this.state.address}
                            handleChange={this.handleInput}
                            name="address"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-3">
                        <CustomInput
                            label="Grad"
                            value={this.state.city}
                            handleChange={this.handleInput}
                            name="city"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-3">
                        <DatePicker
                            onChange={(e) =>
                                this.handleInput("creation_date", e, num - 1)
                            }
                            value={this.state.creation_date}
                            locale={"sr-Latn-ME"}
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-lg-4">
                        <CustomInput
                            label="Ovlašćeno lice"
                            value={this.state.authorized_person}
                            handleChange={this.handleInput}
                            name="authorized_person"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-4">
                        <CustomInput
                            label="Telefon"
                            value={this.state.phone}
                            handleChange={this.handleInput}
                            name="phone"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-4">
                        <CustomInput
                            label="Email"
                            value={this.state.mail}
                            handleChange={this.handleInput}
                            name="mail"
                            index={num - 1}
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-lg-3">
                        <CustomInput
                            label="Website"
                            value={this.state.web}
                            handleChange={this.handleInput}
                            name="web"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-3">
                        <CustomInput
                            label="Facebook"
                            value={this.state.facebook}
                            handleChange={this.handleInput}
                            name="facebook"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-3">
                        <CustomInput
                            label="Instagram"
                            value={this.state.instagram}
                            handleChange={this.handleInput}
                            name="instagram"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-3">
                        <CustomInput
                            label="LinkedIn"
                            value={this.state.linkedin}
                            handleChange={this.handleInput}
                            name="linkedin"
                            index={num - 1}
                        />
                    </div>
                </div>
                <div className="row mt-50">
                    <div className="col-lg-4">
                        <CustomInput
                            label="Izmjeni Logo"
                            value={
                                typeof this.state.logo === "string"
                                    ? null
                                    : this.state.logo
                            }
                            handleChange={this.handleFileInput}
                            name="logo"
                            index={num - 1}
                            type="file"
                            temp="logo"
                            img
                            subMessage="(512x512)"
                        />
                        {this.state.logo &&
                        typeof this.state.logo === "string" ? (
                            <div className="mt-30">
                                <h4 className="f-s-18 text-color-secondary">
                                    Trenutna fotografija:
                                </h4>
                                <img
                                    src={`${server}/${this.state.logo}`}
                                    alt={this.state.logo}
                                    style={{ maxWidth: "80%" }}
                                />
                            </div>
                        ) : (
                            <div className="mt-30">
                                <h4 className="f-s-18 text-color-secondary">
                                    Fotografija odabrana za upload:
                                </h4>
                                <p className="f-s-14 text-color-primary">
                                    {this.state.logo.name}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-4">
                        <CustomInput
                            label="Izmjeni Izvještaj"
                            value={
                                typeof this.state.report === "string"
                                    ? null
                                    : this.state.report
                            }
                            handleChange={this.handleFileInput}
                            name="report"
                            index={num - 1}
                            type="file"
                            temp="report"
                        />
                        {this.state.report &&
                        typeof this.state.report === "string" ? (
                            <div className="mt-30">
                                <h4 className="f-s-18 text-color-secondary">
                                    Trenutni dokument:
                                </h4>
                                <a
                                    style={{ textDecoration: "none" }}
                                    className="f-s-16 text-color-primary f-w-4"
                                    href={`${server}/${this.state.report}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    download={true}
                                >
                                    Pogledaj
                                </a>
                            </div>
                        ) : (
                            <div className="mt-30">
                                <h4 className="f-s-18 text-color-secondary">
                                    Dokument izabran za upload:
                                </h4>
                                <p className="f-s-14 text-color-primary">
                                    {this.state.report.name}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    errorPopups = () => {
        return (
            <>
                <div
                    className={`errorPopup ${
                        this.state.showWarningPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            Nijedna fotografija nije selektovana. Ukoliko
                            nastavite default-na cover fotografija će biti
                            korištena
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.createPage}
                            >
                                Nastavi
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Zatvori
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div
                    className={`errorPopup ${
                        this.state.showUploadPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                            {!this.state.uploadComplete
                                ? "Upload u toku"
                                : "Optimizacija u toku"}
                        </h3>
                        {!this.state.uploadComplete ? (
                            <div className="uploadBar d-flex align-items-center p-r">
                                <div className="barWrapper">
                                    <span
                                        className="bar d-flex align-items-center justify-content-center"
                                        style={{
                                            width: `${this.state.completed}%`,
                                        }}
                                    />
                                </div>
                                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                                    {this.state.completed}%
                                </span>
                            </div>
                        ) : (
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="contentWrapper">
                    <div
                        className={` errorPopup ${
                            this.state.showErrorPopup ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                            <h3 className="f-s-18 f-w-4 uppercase text-center">
                                Problemi prilikom ažuriranja člana
                            </h3>
                            <ul className="mt-30">
                                {Object.keys(this.state.errors).map(
                                    (e, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="f-s-16 text-center"
                                            >
                                                {this.state.errors[e]}
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                            <div className="button text-center mt-30">
                                <CustomButton onClick={this.removePopup}>
                                    Zatvori
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <div
                    className="optimization loader d-flex justify-content-center align-items-center"
                    style={{ height: "calc(100vh - 130px)" }}
                >
                    <div className="fancy-spinner">
                        <div className="ring" />
                        <div className="ring" />
                        <div className="dot" />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="wrapper">
                    <div className="row mt-30">
                        {this.errorPopups()}
                        <form
                            style={{ width: "100%" }}
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div
                                className="contentWrapper"
                                style={{
                                    display:
                                        this.state.activeLang === 1
                                            ? "block"
                                            : "none",
                                }}
                            >
                                {this.renderForm(this.state.activeLang)}
                            </div>
                            <div
                                className="contentWrapper"
                                style={{
                                    display:
                                        this.state.activeLang === 2
                                            ? "block"
                                            : "none",
                                }}
                            >
                                {this.renderForm(this.state.activeLang)}
                            </div>
                        </form>
                        <div className="col-12 d-flex justify-content-end">
                            <CustomButton onClick={this.handleSubmit}>
                                Sačuvaj izmjene
                            </CustomButton>
                            {!this.props.customer && (
                                <LinkButton
                                    href={`/clanovi`}
                                    className="ml-20 red"
                                >
                                    Odustani
                                </LinkButton>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default EditMember;
