import React, { Component } from "react";
import {
    CustomInput,
    CustomTextArea
} from "../../../components/Form/Inputs/Inputs";
import { createSlug } from "../../../functions/functions";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { CustomButton } from "../../../components/Buttons/Button";
import { addPageValidiation } from "../../../functions/validateFunctions";
import {
    addImages,
    deleteFile,
    getAllCategoriesPage,
    getPage,
    updatePage
} from "../../../functions/postFunctions";
import SortableGrid from "../../../components/SortableGrid/SortableGrid";
import DatePicker from "react-date-picker";
import { Constants } from "../../../services/constants.service";

class EditNews extends Component {
    state = {
        pages: [
            {
                language_id: 1,
                title: "",
                description: "",
                content: "",
                slug: "",
                date: new Date(),
                content_right: ""
            },
            {
                language_id: 2,
                title: "",
                description: "",
                content: "",
                slug: "",
                date: new Date(),
                content_right: ""
            }
        ],
        blocks: [],
        errors: [],
        showErrorPopup: false,
        showWarningPopup: false,
        images: null,
        active: "content",
        listOfImages: [],
        stepOne: true,
        edit: true,
        uploadedImages: [],
        completed: 0,
        showUploadPopup: false,
        uploadComplete: false,
        rightSide: false,
        categoryList: [],
        category_id: 1,
        activeLang: 1,
        file_titles: [],
        files: null,
        existingFiles: [],
        uploadedImagesTemp: []
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Izmjeni novost");
        getPage(this.props.match.params.id).then(res => {
            if (res.success) {
                res.pages.date = new Date(res.pages[0].date);
                this.setState({
                    pages: [res.pages[0], res.pages[1]],
                    uploadedImages: res.images,
                    rightSide: res.pages[0].content_right,
                    existingFiles: res.files
                });
            } else {
                this.props.history.push("/");
            }
        });
    }
    toggleRightSideContent = () => {
        this.setState(({ rightSide }) => ({
            rightSide: !rightSide
        }));
    };
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.errors != this.state.errors &&
            this.state.errors.length > 0
        ) {
            this.setState({ showErrorPopup: true });
        }
        if (prevState.stepOne !== this.state.stepOne) {
            const item = document.querySelector(".gallery");
            this.navigationMove(item, "gallery");
        }
        if (
            prevState.pages !== this.state.pages &&
            this.state.pages.length > 0
        ) {
            getAllCategoriesPage().then(res => {
                if (res.success) {
                    const categoryList = res.categories.map(e => ({
                        value: e.id,
                        label: e.name
                    }));
                    const currentCategory = categoryList.find(
                        e => e.value === this.state.pages[0].category_id
                    );
                    this.setState({ categoryList, currentCategory });
                }
            });
        }
        // if (prevState.activeLang !== this.state.activeLang) {
        //     getPage(this.props.match.params.id).then((res) => {
        //         if (res.success) {
        //             res.pages.date = new Date(res.pages[1].date);
        //             this.setState({
        //                 pages: [res.pages[1], { language_id: 2 }],
        //                 uploadedImages: res.images,
        //                 rightSide: res.pages[1].content_right,
        //                 existingFiles: res.files,
        //             });
        //         }
        //     });
        // }
    }
    cancelChanges = () => {
        this.props.history.push("/");
    };

    handleInputBlocks = (name, value, index) => {
        this.setState(({ blocks }) => ({
            blocks: [
                ...blocks.slice(0, index),
                {
                    ...blocks[index],
                    [name]: value
                },
                ...blocks.slice(index + 1)
            ]
        }));
    };

    handleInput = (name, value, index) => {
        if (name === "currentCategory") {
            this.setState({ [name]: value });
        } else if (name === "title") {
            this.setState(({ pages }) => ({
                pages: [
                    ...pages.slice(0, index),
                    {
                        ...pages[index],
                        title: value,
                        slug: createSlug(value)
                    },
                    ...pages.slice(index + 1)
                ]
            }));
        } else {
            this.setState(({ pages }) => ({
                pages: [
                    ...pages.slice(0, index),
                    {
                        ...pages[index],
                        [name]: value
                    },
                    ...pages.slice(index + 1)
                ]
            }));
        }
    };

    handleFileInput = (event, temp) => {
        if (temp === "temp") {
            this.setState({ images: [...event] }, () => {
                this.handleAddImages();
            });
        } else if (temp === "files") {
            this.setState({ files: [...event] });
        } else {
            this.setState({ images: [...event] });
        }
    };

    handleFileTitleInput = (name, value, index) => {
        this.setState(({ file_titles }) => ({
            file_titles: [
                ...file_titles.slice(0, index),
                {
                    ...file_titles[index],
                    title: value
                },
                ...file_titles.slice(index + 1)
            ]
        }));
    };

    removePopup = stay => {
        if (!stay) {
            this.props.history.push("/");
        } else {
            this.setState({ showErrorPopup: false });
        }
    };
    handleSubmit = event => {
        event.preventDefault();
        const { active } = this.state;
        const errors = addPageValidiation(this.state, true);
        if (active !== "gallery") {
            if (errors.length === 0) {
                this.editPage();
            } else {
                this.setState({ errors });
            }
        } else {
            this.handleAddImages();
        }
    };
    editPage = () => {
        const { files } = this.state;
        const data = new FormData();
        if (files) {
            files.forEach(e => {
                data.append("files[]", e);
            });
        }

        this.setState({ showUploadPopup: true });
        const config = {
            onUploadProgress: progressEvent => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100
                });
            }
        };

        const pages = this.state.pages.map(e => {
            e.category_id = this.state.currentCategory.value;
            return e;
        });

        data.append("pages", JSON.stringify(pages));
        console.log(data);
        updatePage(data, this.props.token, config).then(res => {
            if (res.success) {
                this.setState({
                    showWarningPopup: true,
                    showUploadPopup: false
                });
            } else {
                this.setState({ showErrorPopup: true, showUploadPopup: false });
            }
        });
    };

    handleAddTempImages = () => {
        const { images } = this.state;
        const data = new FormData();
        if (images.length > 0) {
            images.forEach(e => {
                data.append("images[]", e);
            });
        }
        data.append("page_id", 0);
        const config = {
            onUploadProgress: progressEvent => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100
                });
            }
        };
        addImages(data, this.props.token, config).then(res => {
            if (res.success) {
                this.setState(({ uploadedImagesTemp }) => ({
                    uploadedImagesTemp: [...uploadedImagesTemp, ...res.images],
                    images: null
                }));
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    handleAddImages = () => {
        const { images, pages } = this.state;
        const data = new FormData();
        if (images.length > 0) {
            images.forEach(e => {
                data.append("images[]", e);
            });
        }
        data.append("page_id", pages[0].page_id);
        const config = {
            onUploadProgress: progressEvent => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100
                });
            }
        };
        addImages(data, this.props.token, config).then(res => {
            if (res.success) {
                this.props.history.push("/");
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    removeImage = id => {
        const { uploadedImages } = this.state;

        const newList = uploadedImages.filter(e => e.id !== id);
        this.setState({ uploadedImages: newList });
    };

    underlineRef = React.createRef();

    navigationMove = (item, activeLang, active) => {
        item.parentElement.querySelectorAll(".link").forEach(e => {
            e.classList.remove("text-color-primary");
            e.classList.remove("text-color-font-l");
        });
        item.classList.add("text-color-primary");
        this.underlineRef.current.style.transform = `translateX(${item.offsetLeft}px)`;
        this.setState({ active, activeLang });
    };

    setActiveLang = activeLang => {
        this.setState({ activeLang });
    };

    addBlock = () => {
        const newBlocks = [
            {
                language_id: 1,
                title: "",
                content: "",
                file: ""
            },
            {
                language_id: 2,
                title: "",
                content: "",
                file: ""
            }
        ];
        this.setState(({ blocks }) => ({
            blocks: [...blocks, ...newBlocks]
        }));
    };

    addFile = () => {
        this.setState(({ file_titles, activeLang }) => ({
            file_titles: [
                ...file_titles,
                { title: "", language_id: activeLang + 1 }
            ]
        }));
    };

    handleDeleteFile = id => {
        const prevState = [...this.state.existingFiles];
        const existingFiles = this.state.existingFiles.filter(e => e.id !== id);

        this.setState({ existingFiles });

        deleteFile(id, this.props.token).then(res => {
            if (!res.success) {
                this.setState({ existingFiles: prevState });
            }
        });
    };

    copyLink = link => {
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    };

    renderForm = (num, categoryList) => {
        const server = Constants.SERVER;
        console.log(this.state.pages);
        return (
            <div className="langWrapper">
                <div className="row mt-50">
                    <div className="col-lg-4">
                        <CustomInput
                            label="Naslov"
                            value={this.state.pages[num - 1].title}
                            handleChange={this.handleInput}
                            name="title"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-4">
                        <DatePicker
                            onChange={e => this.handleInput("date", e, num - 1)}
                            value={new Date(this.state.pages[num - 1].date)}
                            locale={"sr-Latn-ME"}
                        />
                    </div>
                    <div className="col-lg-4">
                        <CustomInput
                            label="Link"
                            value={this.state.pages[num - 1].slug}
                            handleChange={this.handleInput}
                            name="slug"
                            readOnly
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-12">
                        <CustomTextArea
                            label="Opis"
                            value={this.state.pages[num - 1].description}
                            handleChange={this.handleInput}
                            name="description"
                            className="textArea"
                            index={num - 1}
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-12 text-right">
                        {/*<CustomButton onClick={this.toggleRightSideContent}>*/}
                        {/*  {!this.state.rightSide*/}
                        {/*    ? "Dodaj drugu kolonu"*/}
                        {/*    : "Ukloni drugu kolonu"}*/}
                        {/*</CustomButton>*/}
                    </div>
                </div>
                <div className="row mt-10">
                    <div className="col-12">
                        <TextEditor
                            name="content"
                            handleInput={this.handleInput}
                            index={num - 1}
                            value={this.state.pages[num - 1].content}
                        />
                    </div>
                </div>
                <div className="row mt-60">
                    <div className="col-12">
                        <CustomInput
                            label="Dodaj fotografije"
                            value={null}
                            handleChange={this.handleFileInput}
                            name="images[]"
                            index={0}
                            type="file"
                            multiple
                            className="mt-40"
                            temp="temp"
                        />
                        {this.state.uploadedImagesTemp.length > 0 ? (
                            <div className="selectedImages my-20 py-10">
                                <h3 className="f-s-16 text-color-primary f-w-4">
                                    Uploadovane fotografije:
                                </h3>
                                <ul className="mt-20">
                                    {this.state.uploadedImagesTemp.map(
                                        (e, index) => {
                                            return (
                                                <li
                                                    style={{
                                                        listStyle: "none"
                                                    }}
                                                    key={index}
                                                    className="f-s-12 d-flex align-items-center"
                                                >
                                                    <img
                                                        src={`${server}/${e}`}
                                                        style={{
                                                            width: "200px"
                                                        }}
                                                        alt=""
                                                    />
                                                    <button
                                                        className="btn btn-primary f-s-16 text-color-white transition-1 px-20 py-5 br-r-5 d-flex align-items-center justify-content-center lh ml-20"
                                                        style={{
                                                            borderRadius: "8px"
                                                        }}
                                                        onClick={() =>
                                                            this.copyLink(
                                                                `${server}/${e}`
                                                            )
                                                        }
                                                    >
                                                        Kopiraj link
                                                    </button>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="row mt-60">
                    <div className="col-12">
                        <CustomInput
                            label="Dodaj fajl"
                            value={null}
                            handleChange={this.handleFileInput}
                            name="file[]"
                            index={0}
                            type="file"
                            multiple
                            className="mt-40"
                            temp="files"
                        />
                        <div className="row">
                            <div className="col-12">
                                <div className="galleryWrapper d-inline-block">
                                    {this.state.files ? (
                                        <div className="selectedImages my-20 py-10 px-15">
                                            <h3 className="f-s-16 text-color-primary f-w-4">
                                                Fajlovi odabrani za upload:
                                            </h3>
                                            <ul className="mt-20">
                                                {this.state.files.map(
                                                    (e, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="f-s-12"
                                                            >
                                                                {index + 1}.{" "}
                                                                {e.name}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="galleryWrapper d-inline-block">
                                    {this.state.existingFiles.length > 0 ? (
                                        <div className="selectedImages my-20 py-10 px-15">
                                            <h3 className="f-s-16 text-color-primary f-w-4">
                                                Postojeći fajlovi:
                                            </h3>
                                            <ul className="mt-20">
                                                {this.state.existingFiles.map(
                                                    (e, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="f-s-12 d-flex justify-content-between mt-20 align-items-center"
                                                            >
                                                                <span className="mr-40">
                                                                    {index + 1}.{" "}
                                                                    {e.file
                                                                        .split(
                                                                            "\\"
                                                                        )
                                                                        .pop()
                                                                        .split(
                                                                            "/"
                                                                        )
                                                                        .pop()}
                                                                </span>
                                                                <CustomButton
                                                                    className="red"
                                                                    onClick={() =>
                                                                        this.handleDeleteFile(
                                                                            e.id
                                                                        )
                                                                    }
                                                                >
                                                                    Izbriši
                                                                </CustomButton>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { categoryList, currentCategory, file_titles } = this.state;
        let counter = 0;
        return (
            <div className="wrapper">
                <div
                    className={`errorPopup ${
                        this.state.showUploadPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                            {!this.state.uploadComplete
                                ? "Upload u toku"
                                : "Optimizacija u toku"}
                        </h3>
                        {!this.state.uploadComplete ? (
                            <div className="uploadBar d-flex align-items-center p-r">
                                <div className="barWrapper">
                                    <span
                                        className="bar d-flex align-items-center justify-content-center"
                                        style={{
                                            width: `${this.state.completed}%`
                                        }}
                                    />
                                </div>
                                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                                    {this.state.completed}%
                                </span>
                            </div>
                        ) : (
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={` errorPopup ${
                        this.state.showWarningPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                            Uspjeh
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            Stranica uspješno ažurirana
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                onClick={() => this.removePopup(false)}
                            >
                                U redu
                            </CustomButton>
                        </div>
                    </div>
                </div>
                {this.state.edit ? (
                    <div className="innerNavigation pb-10 mb-40">
                        <span
                            className="f-s-16 mr-20 uppercase text-color-primary link"
                            onClick={e =>
                                this.navigationMove(
                                    e.currentTarget,
                                    1,
                                    "content"
                                )
                            }
                        >
                            Crnogorski
                        </span>
                        <span
                            className="f-s-16 mr-20 uppercase text-color-primary link"
                            onClick={e =>
                                this.navigationMove(
                                    e.currentTarget,
                                    2,
                                    "content"
                                )
                            }
                        >
                            Engleski
                        </span>
                        <span
                            className="f-s-16 uppercase text-color-font-l link gallery mr-20"
                            onClick={e =>
                                this.navigationMove(e.currentTarget, "gallery")
                            }
                        >
                            Cover Fotografija
                        </span>
                        {/*<span*/}
                        {/*  className="f-s-16 uppercase text-color-font-l link gallery"*/}
                        {/*  onClick={e => this.navigationMove(e.currentTarget, "blocks")}*/}
                        {/*>*/}
                        {/*  Blokovi*/}
                        {/*</span>*/}
                        <span className="indicator" ref={this.underlineRef} />
                    </div>
                ) : (
                    ""
                )}
                {this.state.active === "content" ? (
                    <div className="contentWrapper">
                        <div
                            className={` errorPopup ${
                                this.state.showErrorPopup ? "popupShown" : ""
                            }`}
                        >
                            <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                                <h3 className="f-s-18 f-w-4 uppercase text-center">
                                    Problemi prilikom ažuriranja
                                </h3>
                                <ul className="mt-30">
                                    {this.state.errors.map((e, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="f-s-16 text-center"
                                            >
                                                {e}
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="button text-center mt-30">
                                    <CustomButton
                                        onClick={() => this.removePopup(true)}
                                    >
                                        Zatvori
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                        <form
                            style={{ width: "100%" }}
                            onSubmit={e => e.preventDefault()}
                        >
                            <div
                                className="contentWrapper"
                                style={{
                                    display:
                                        this.state.activeLang === 1
                                            ? "block"
                                            : "none"
                                }}
                            >
                                {this.renderForm(
                                    this.state.activeLang,
                                    categoryList
                                )}
                            </div>
                            <div
                                className="contentWrapper"
                                style={{
                                    display:
                                        this.state.activeLang === 2
                                            ? "block"
                                            : "none"
                                }}
                            >
                                {this.renderForm(
                                    this.state.activeLang,
                                    categoryList
                                )}
                            </div>
                        </form>
                    </div>
                ) : this.state.active === "gallery" ? (
                    <div className="galleryWrapper">
                        <CustomInput
                            label="Dodaj cover"
                            value={null}
                            handleChange={this.handleFileInput}
                            name="file[]"
                            index={0}
                            type="file"
                            multiple
                            subMessage="(1600x870)"
                        />
                        {this.state.images ? (
                            <div className="selectedImages my-20 py-10 px-15">
                                <h3 className="f-s-16 text-color-primary f-w-4">
                                    Fotografije odabrane za upload:
                                </h3>
                                <ul className="mt-20">
                                    {this.state.images.map((e, index) => {
                                        return (
                                            <li key={index} className="f-s-12">
                                                {index + 1}. {e.name}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : (
                            ""
                        )}
                        <SortableGrid
                            images={this.state.uploadedImages}
                            edit
                            token={this.props.token}
                            removeImage={this.removeImage}
                        />
                    </div>
                ) : (
                    <div className="contentWrapper">
                        <CustomButton onClick={this.addBlock}>
                            Dodaj blok
                        </CustomButton>
                        <div className="row mt-30">
                            {this.state.blocks.map((e, index) => {
                                return (
                                    <div className="col-lg-6 mt-30" key={index}>
                                        <div className="row">
                                            <div className="col-12">
                                                <CustomInput
                                                    label={
                                                        (index + 1) % 2 !== 0
                                                            ? "Naslov"
                                                            : "Naslov EN"
                                                    }
                                                    value={
                                                        this.state.blocks[index]
                                                            .title
                                                    }
                                                    handleChange={
                                                        this.handleInputBlocks
                                                    }
                                                    name="title"
                                                    index={index}
                                                />
                                            </div>
                                            <div className="col-12 mt-30">
                                                <TextEditor
                                                    name="content"
                                                    handleInput={
                                                        this.handleInputBlocks
                                                    }
                                                    index={index}
                                                    value={
                                                        this.state.blocks[index]
                                                            .content
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className="row mt-30">
                    <div className="col-12 d-flex justify-content-end">
                        <CustomButton
                            onClick={e => this.handleSubmit(e)}
                            className="mr-20"
                        >
                            {this.state.active === "content"
                                ? "Ažuriraj novost"
                                : this.state.active === "gallery"
                                ? "Dodaj fotografije"
                                : "Ažuriraj novost"}
                        </CustomButton>
                        <CustomButton
                            className="red"
                            onClick={this.cancelChanges}
                        >
                            Odustani od izmjena
                        </CustomButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditNews;
