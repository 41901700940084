import React, { Component } from "react";
import {
  deleteMenu,
  deleteMenuItem,
  getAllMenuItems
} from "../../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../../components/Buttons/Button";
import SortableList from "../../../components/SortableGrid/SortableList";
import AddMenuItem from "../../../components/AddMenuItem/AddMenuItem";
import EditMenuItem from "../../../components/EditMenuItem/EditMenuItem";

class MenusItems extends Component {
  state = {
    theads: ["Naziv", "Pozicija", "Akcije"],
    menus: [],
    positions: [
      {
        value: 0,
        label: "Glavni Meni"
      },
      {
        value: 1,
        label: "Meni na vrhu"
      },
      {
        value: 2,
        label: "Lijevi meni"
      },
      {
        value: 3,
        label: "Desni Meni"
      },
      {
        value: 4,
        label: "Meni u podnožju - Lijevo"
      },
      {
        value: 5,
        label: "Meni u podnožju - Desno"
      }
    ],
    items: [],
    showAddMenuItem: false,
    showEditMenuItem: false,
    itemForEdit: null,
    menu: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Stavke Menija");
    getAllMenuItems(this.props.match.params.id).then(res => {
      if (res.success) {
        this.setState({ items: res.items, menu: res.menu });
      }
    });
  }

  addToList = items => {
    this.setState({ items }, () => this.hideModal());
  };

  handleDeletePage = id => {
    deleteMenu(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ menus: res.menus });
      }
    });
  };

  findAllMenus = array => {
    let menus = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  showModal = (name, id) => {
    let item;
    if (id) {
      const items = this.findAllMenus(this.state.items);
      item = items.find(e => e.id === id);
    }
    this.setState({ [name]: true, itemForEdit: item });
  };

  hideModal = () => {
    this.setState({
      showAddMenuItem: false,
      itemForEdit: null
    });
  };

  handleDeleteMenuItem = id => {
    this.setState({ items: [] });
    deleteMenuItem(id, this.props.match.params.id, this.props.token).then(
      res => {
        if (res.success) {
          this.setState({ items: res.items });
        }
      }
    );
  };

  listElement = el => {
    return (
      <SortableList
        items={el}
        key={this.props.key}
        listElement={this.listElement}
        token={this.props.token}
        deleteMenuItem={this.handleDeleteMenuItem}
        showModal={this.showModal}
      />
    );
  };

  render() {
    const { items } = this.state;
    return (
      <div>
        <div className="pageTop mb-40">
          <CustomButton onClick={() => this.showModal("showAddMenuItem")}>
            Dodaj stavku
          </CustomButton>
          <AddMenuItem
            visible={this.state.showAddMenuItem}
            hideModal={this.hideModal}
            token={this.props.token}
            addToList={this.addToList}
            menus={items}
            id={this.props.match.params.id}
            menuName={this.state.menu && this.state.menu.name}
          />
          <EditMenuItem
            visible={this.state.showEditMenuItem}
            hideModal={this.hideModal}
            token={this.props.token}
            addToList={this.addToList}
            menus={items}
            id={this.props.match.params.id}
            itemForEdit={this.state.itemForEdit}
            menuName={this.state.menu && this.state.menu.name}
          />
        </div>
        
        <div className="row my-20">
          <div className="col-12">
            <div className="menuList">
              {items.length > 0 ? (
                this.listElement(items)
              ) : (
                <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                  <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                    Učitavanje
                  </h3>
                  <div className="optimization loader d-flex justify-content-center align-items-center">
                    <div className="fancy-spinner">
                      <div className="ring" />
                      <div className="ring" />
                      <div className="dot" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenusItems;
