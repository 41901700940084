import React, { Component } from "react";
import {
    addDocument,
    deleteDocument,
    getAllDocuments,
    getAllPages,
} from "../../functions/postFunctions";

import { CustomButton } from "../../components/Buttons/Button";
import { CustomInput } from "../../components/Form/Inputs/Inputs";
import Table from "../../components/Table/Table";
import EditDocument from "./EditDocument";
import { Constants } from "../../services/constants.service";

class LibraryPage extends Component {
    state = {
        theads:
            this.props.type === 0
                ? ["Naslov", "Dokument", "Akcije"]
                : ["Cover", "Naslov", "Dokument", "Akcije"],
        pages: [],
        removeLoadMore: true,
        page: 1,
        categoryList: [],
        currentCategory: 1,
        title: "",
        date: new Date(),
        document: null,
        popupShown: false,
        cover: null,
        documentEdit: [],
        warningModal: false,
        deletionID: 0,
        newDocument: "",
        documentPublication: null,
    };

    componentDidMount() {
        if (this.props.type === 0) {
            this.props.setBreadcrumbs("Dokumenti");
        } else {
            this.props.setBreadcrumbs("Publikacije");
        }
        this.loadNews();
    }
    handleDeletePage = () => {
        const id = this.state.deletionID;

        const { pages } = this.state;
        const newPages = pages.filter((e) => e.id !== id);
        this.setState({ warningModal: false, deletionID: 0 });

        deleteDocument(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ pages: newPages });
            }
        });
    };

    loadNews = () => {
        getAllDocuments(this.props.type).then((res) => {
            if (res.success) {
                const pages =
                    this.props.type === 0 ? res.akta : res.publikacije;
                this.setState({
                    pages: pages,
                    page: 1,
                    removeLoadMore: pages.length < 20,
                });
            }
        });
    };

    loadMoreNews = () => {
        const { page, currentCategory } = this.state;
        getAllPages(1, 20, page * 20, currentCategory.value).then((res) => {
            if (res.success) {
                this.setState(({ pages, page }) => ({
                    pages: [...pages, ...res.pages],
                    page: page + 1,
                    removeLoadMore: res.pages.length < 20,
                }));
            }
        });
    };

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleAddAnnouncement = () => {
        const { title, document, cover, documentPublication } = this.state;

        const data = new FormData();

        data.append("title", title);
        data.append("file", document);
        data.append("type", this.props.type);

        if (this.props.type === 1) {
            data.append("cover", cover);
            data.append("file", documentPublication);
        }

        this.setState({ popupShown: true });
        addDocument(data, this.props.token).then((res) => {
            if (res.success) {
                this.setState(({ pages }) => ({
                    pages: [res.document, ...pages],
                    title: "",
                    date: new Date(),
                    document: null,
                    popupShown: false,
                    cover: null,
                    documentPublication: null,
                }));
            } else {
                this.setState({ popupShown: false });
            }
        });
    };

    handleFileInput = (event, name) => {
        this.setState({ [name]: event[0] });
    };

    renderActs = () => {
        return (
            <div className="row">
                <div className="col-lg-3">
                    <CustomInput
                        label="Naslov"
                        value={this.state.title}
                        handleChange={this.handleInput}
                        name="title"
                    />
                </div>
                <div className="col-2">
                    <CustomInput
                        label="Izaberi fajl"
                        value={null}
                        handleChange={this.handleFileInput}
                        name="document"
                        index={0}
                        type="file"
                        className="mt-40"
                        temp="document"
                    />
                    <div className="galleryWrapper">
                        {this.state.document ? (
                            <div className="selectedImages my-20 py-10 px-15">
                                <h3 className="f-s-16 text-color-primary f-w-4">
                                    Fajl odabran za upload:
                                </h3>
                                <p className="mt-20">
                                    {this.state.document.name}
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <CustomButton
                        className="green"
                        onClick={this.handleAddAnnouncement}
                    >
                        Dodaj Aktu
                    </CustomButton>
                </div>
            </div>
        );
    };
    showModal = (id) => {
        console.log(id);
        let newDocument;
        if (id) {
            const { pages } = this.state;
            newDocument = pages.find((e) => e.id === id);
        }
        this.setState({ showEditCompetition: true, documentEdit: newDocument });
    };

    hideModal = () => {
        this.setState({
            showAddCompetition: false,
            showEditCompetition: false,
            competitionForEdit: null,
        });
    };
    renderPublications = () => {
        return (
            <>
                <div className="row align-items-center">
                    <div className="col-lg-4 ">
                        <CustomInput
                            label="Naslov"
                            value={this.state.title}
                            handleChange={this.handleInput}
                            name="title"
                            className="w-100"
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-4 d-flex align-items-center">
                        <CustomInput
                            label="Izaberi fajl"
                            value={null}
                            handleChange={this.handleFileInput}
                            name="document"
                            index={0}
                            type="file"
                            className="mt-40"
                            temp="document"
                        />
                        <div className="galleryWrapper ml-30">
                            {this.state.document ? (
                                <div className="selectedImages py-10 px-15">
                                    <h3 className="f-s-16 text-color-primary f-w-4">
                                        Fajl odabran za upload:
                                    </h3>
                                    <p className="mt-20">
                                        {this.state.document.name}
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-4 d-flex align-items-center">
                        <CustomInput
                            label="Izaberi cover"
                            value={null}
                            handleChange={this.handleFileInput}
                            name="cover"
                            index={0}
                            type="file"
                            className="mt-40"
                            temp="cover"
                            subMessage="(512x512)"
                        />
                        <div className="galleryWrapper">
                            {this.state.cover ? (
                                <div className="selectedImages ml-30 py-10 px-15">
                                    <h3 className="f-s-16 text-color-primary f-w-4">
                                        Fotografija odabrana za upload:
                                    </h3>
                                    <p className="mt-20">
                                        {this.state.cover.name}
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-md-3">
                        <CustomButton
                            className="green"
                            onClick={this.handleAddAnnouncement}
                        >
                            Dodaj Publikaciju
                        </CustomButton>
                    </div>
                </div>
            </>
        );
    };
    showDeleteModal = (id) => {
        this.setState({ warningModal: true, deletionID: id });
    };

    removePopup = () => {
        this.setState({ warningModal: false, deletionID: 0 });
    };
    render() {
        const { theads, pages, documentEdit } = this.state;
        const server = Constants.SERVER;

        const type = this.props.type;
        console.log(documentEdit);
        return (
            <div>
                {this.state.showEditCompetition && (
                    <EditDocument
                        visible={this.state.showEditCompetition}
                        hideModal={this.hideModal}
                        token={this.props.token}
                        refreshList={this.refreshList}
                        documentEdit={documentEdit}
                        type={type}
                    />
                )}
                <div
                    className={`errorPopup ${
                        this.state.warningModal ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            {type === 0
                                ? "Da li ste sigurni da želite da izbrišete dokument?"
                                : " Da li ste sigurni da želite da izbrišete publikaciju?"}{" "}
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.handleDeletePage}
                            >
                                Izbriši
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Odustani
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div
                        className={`errorPopup ${
                            this.state.popupShown ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                                {type === 0
                                    ? "Kreiranje dokumenta u toku"
                                    : "Kreiranje publikacije"}
                            </h3>
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.popupShown && (
                    <div className="pageTop mb-40">
                        {type === 0
                            ? this.renderActs()
                            : this.renderPublications()}
                    </div>
                )}
                <Table theads={theads}>
                    {pages.map((e, index) => {
                        return (
                            <tr key={index}>
                                {type === 1 && (
                                    <td className="td px-10">
                                        <img
                                            src={`${server}/${e.cover}`}
                                            alt={e.cover}
                                            style={{ maxHeight: "50px" }}
                                        />
                                    </td>
                                )}
                                <td className="td px-10">
                                    <span className="f-s-16">{e.title}</span>
                                </td>
                                <td className="td px-10">
                                    <a
                                        href={`https://economicteam.me//${e.document}`}
                                        className="f-s-16 transition-1 py-5 br-r-5 text-color-primary regular-link"
                                        rel="noreferrer noopener"
                                        download
                                    >
                                        Preuzmi dokument
                                    </a>
                                </td>
                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    <CustomButton
                                        onClick={() => this.showModal(e.id)}
                                        className="mr-20"
                                    >
                                        Izmijeni
                                    </CustomButton>
                                    <CustomButton
                                        onClick={() =>
                                            this.showDeleteModal(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                {!this.state.removeLoadMore ? (
                    <div className="wrapper mt-40 d-flex justify-content-center">
                        <CustomButton onClick={this.loadMoreNews}>
                            Učitaj još
                        </CustomButton>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default LibraryPage;
