import React from "react";

const Logo = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="497.569"
      height="105.436"
      viewBox="0 0 497.569 105.436"
      id="logoColor"
    >
      <g data-name="Group 636" transform="translate(-422.68 -426.141)">
        <g data-name="Group 400" transform="translate(507.079 455.752)">
          <g data-name="Group 399">
            <path
              d="M613.595 521.182H628.7v8.308h-23.737v-37.764h23.468v8.308h-14.836v6.258h13.487v8.2h-13.487z"
              className="cls-1"
              data-name="Path 803"
              transform="translate(-604.963 -490.971)"
            />
            <path
              d="M668.193 523.759a19.2 19.2 0 01-5.557-14.026 19.539 19.539 0 0135.822-10.9l-7.445 4.316c-1.672-2.967-4.909-4.64-8.74-4.64a10.729 10.729 0 00-8.038 3.075 12.665 12.665 0 000 16.292 10.73 10.73 0 008.038 3.075 9.75 9.75 0 008.74-4.639l7.445 4.316a18.647 18.647 0 01-16.184 8.74 19.108 19.108 0 01-14.081-5.609z"
              className="cls-1"
              data-name="Path 804"
              transform="translate(-635.933 -490.095)"
            />
            <path
              d="M763.715 529.37a19.094 19.094 0 01-13.915-5.67 19.929 19.929 0 010-27.945 19.933 19.933 0 0127.838 0 19.929 19.929 0 010 27.945 19.093 19.093 0 01-13.923 5.67zm0-8.416a10.573 10.573 0 007.823-3.129 11.877 11.877 0 000-16.184 10.571 10.571 0 00-7.823-3.13 10.693 10.693 0 00-7.876 3.13 12.027 12.027 0 000 16.184 10.694 10.694 0 007.876 3.128z"
              className="cls-1"
              data-name="Path 805"
              transform="translate(-679.666 -490.095)"
            />
            <path
              d="M860.424 491.726h8.632v37.764h-6.474l-14.566-20.5v20.5h-8.632v-37.764h6.474l14.566 20.5z"
              className="cls-1"
              data-name="Path 806"
              transform="translate(-730.845 -490.971)"
            />
            <path
              d="M933.588 529.37a19.093 19.093 0 01-13.919-5.665 19.928 19.928 0 010-27.945 19.932 19.932 0 0127.837 0 19.929 19.929 0 010 27.945 19.093 19.093 0 01-13.918 5.665zm0-8.416a10.572 10.572 0 007.822-3.129 11.877 11.877 0 000-16.184 10.57 10.57 0 00-7.822-3.13 10.7 10.7 0 00-7.877 3.13 12.028 12.028 0 000 16.184 10.7 10.7 0 007.877 3.128z"
              className="cls-1"
              data-name="Path 807"
              transform="translate(-770.886 -490.095)"
            />
            <path
              d="M1047.02 491.726v37.764h-8.632v-21.957l-9.765 16.022h-.971l-9.764-16.022v21.957h-8.632v-37.764h8.632l10.25 16.778 10.25-16.778z"
              className="cls-1"
              data-name="Path 808"
              transform="translate(-822.064 -490.971)"
            />
            <path
              d="M1104.794 491.726h8.632v37.764h-8.632z"
              className="cls-1"
              data-name="Path 809"
              transform="translate(-873.367 -490.971)"
            />
            <path
              d="M1139.478 523.759a19.2 19.2 0 01-5.557-14.026 19.539 19.539 0 0135.822-10.9l-7.445 4.316c-1.672-2.967-4.909-4.64-8.74-4.64a10.729 10.729 0 00-8.038 3.075 12.664 12.664 0 000 16.292 10.731 10.731 0 008.038 3.075 9.749 9.749 0 008.74-4.639l7.445 4.316a18.647 18.647 0 01-16.185 8.74 19.108 19.108 0 01-14.08-5.609z"
              className="cls-1"
              data-name="Path 810"
              transform="translate(-889.008 -490.095)"
            />
            <path
              d="M1243.5 491.726v3.561h-11.6v34.2h-3.777v-34.2h-11.6v-3.561z"
              className="cls-2"
              data-name="Path 811"
              transform="translate(-933.367 -490.971)"
            />
            <path
              d="M1290.21 525.929h18.342v3.561h-22.118v-37.764h21.849v3.561h-18.073v13.379h16.724v3.561h-16.724z"
              className="cls-2"
              data-name="Path 812"
              transform="translate(-970.906 -490.971)"
            />
            <path
              d="M1370.109 529.49l-3.507-9.279h-17.911l-3.507 9.279h-3.992l14.512-37.764h3.885l14.511 37.764zm-20.069-12.84h15.214l-7.607-20.23z"
              className="cls-2"
              data-name="Path 813"
              transform="translate(-1000.311 -490.971)"
            />
            <path
              d="M1457.276 491.726v37.764h-3.776v-32.045l-13.217 22.118h-.54l-13.217-22.118v32.045h-3.777v-37.764h4.693l12.57 21.04 12.57-21.04z"
              className="cls-2"
              data-name="Path 814"
              transform="translate(-1044.106 -490.971)"
            />
          </g>
        </g>
        <g data-name="Group 402" transform="translate(422.68 426.141)">
          <g data-name="Group 401" transform="translate(.017 49.706)">
            <path
              d="M422.716 593.155v25.577l13.9-11.027v-19.26c-6.599 3.511-10.99 4.455-13.9 4.71z"
              className="cls-1"
              data-name="Path 815"
              transform="translate(-422.716 -563.003)"
            />
            <path
              d="M502.858 544.016v19.934l14.037-11.137v-19.318a498.997 498.997 0 01-14.037 10.521z"
              className="cls-1"
              data-name="Path 816"
              transform="translate(-465.751 -533.495)"
            />
          </g>
          <path
            d="M476.674 467.06l-14.037 11.14v28c4.575-3.209 9.388-6.83 14.037-10.46z"
            className="cls-2"
            data-name="Path 817"
            transform="translate(-444.137 -448.114)"
          />
          <path
            d="M516.894 434.757v-8.616l-14.036 11.138v32.858a668.894 668.894 0 0014.036-11.551z"
            className="cls-2"
            data-name="Path 818"
            transform="translate(-465.735 -426.141)"
          />
          <path
            d="M436.614 507.979l-13.9 11.027V539.2c3.511-1.073 8.409-4.285 13.9-7.9z"
            className="cls-2"
            data-name="Path 819"
            transform="translate(-422.699 -470.087)"
          />
          <path
            d="M478.385 486.775l1.92 2.885c-7.705 6.691-43.669 37.488-57.626 41.742v3.121c7.505-1.319 18.5-7.751 18.5-7.75v18.039l14.037-11.138v-16l.206-.143c7.96-5.536 17.25-12.535 28.866-21.893l1.939 2.912 6.279-12.679z"
            className="cls-1"
            data-name="Path 820"
            transform="translate(-422.68 -458.216)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
