export const addPageValidiation = (state, edit) => {
    const errors = [];
    state.pages.map((e, index) => {
        // const jezik = index === 0 ? "Crnogorski" : "Engleski";
        if (index === 0) {
            if (e.title === "") {
                errors.push(`Naslov je obavezno polje!`);
            }
        } else {
            if (e.title === "") {
                errors.push("Naslov EN je obavezno polje!");
            }
        }
    });
    if (state.category_id === 0 && !edit) {
        errors.push(`Kategorija je obavezno polje!`);
    }
    return errors;
};
export const addReservationValidation = (data) => {
    const errors = [];
    let isValid = true;
    Object.keys(data).forEach((e) => {
        if (data[e] === "" || data[e] === undefined) {
            isValid = false;
        }
    });
    if (!isValid) {
        errors.push("Svako polje je obavezno");
    }
    return errors;
};
export const addMemberValidation = (data) => {
    const items = [
        {
            name: "name",
            errorMessage: "Naziv je obavezno polje",
        },
        {
            name: "address",
            errorMessage: "Adresa je obavezno polje",
        },
        {
            name: "city",
            errorMessage: "Grad je obavezno polje",
        },
        {
            name: "creation_date",
            errorMessage: "Datum osnivanja je obavezno polje",
        },
        {
            name: "authorized_person",
            errorMessage: "Ovlašćeno lice je obavezno polje",
        },
        {
            name: "phone",
            errorMessage: "Telefon je obavezno polje",
        },
        {
            name: "mail",
            errorMessage: "Email je obavezno polje",
        },
        {
            name: "logo",
            errorMessage: "Logo je obavezno polje",
        },
    ];
    const errors = [];
    items.forEach((e) => {
        if (
            data[e.name] === "" ||
            data[e.name] === undefined ||
            data[e.name] === null
        ) {
            errors.push(e.errorMessage);
        }
    });

    return errors;
};

export const addTestimonialVerification = (data) => {
    const items = [
        {
            name: "name",
            errorMessage: "Ime je obavezno polje",
        },
        {
            name: "company",
            errorMessage: "Kompanija je obavezno polje",
        },
        {
            name: "testimonial",
            errorMessage: "Testimonial CG je obavezno polje",
        },
        {
            name: "testimonial_en",
            errorMessage: "Testimonial EN je obavezno polje",
        },
        { name: "image", errorMessage: "Fotografija je obavezno polje" },
    ];
    const errors = [];
    items.forEach((e) => {
        if (
            data[e.name] === "" ||
            data[e.name] === undefined ||
            data[e.name] === null
        ) {
            errors.push(e.errorMessage);
        }
    });

    return errors;
};
export const addProductValidation = (state, edit) => {
    const errors = [];
    if (state.name === "") {
        errors.push(`Naziv je obavezno polje!`);
    }
    if (state.category_id === 0 && !edit) {
        errors.push(`Kategorija je obavezno polje!`);
    }
    return errors;
};

export const addUserValidation = (state) => {
    const errors = {};
    if (state.username === "") {
        errors.username = ["Korisničko ime je obavezno polje."];
    }

    return errors;
};

export const addTeamValidation = (team, edit) => {
    const errors = [];

    if (team.name === "") {
        errors.push(`Naziv je obavezno polje!`);
    }
    if (team.location === "") {
        errors.push(`Lokacija je obavezno polje!`);
    }
    return errors;
};

export const addCompetitionValidation = (name) => {
    const errors = [];
    if (name === "") {
        errors.push(`Naziv je obavezno polje!`);
    }
    return errors;
};

export const addMenuItemValidiation = (state, edit) => {
    const errors = [];
    const { name, parent_id, linkType } = state;
    if (name === "") {
        errors.push(`Naziv je obavezno polje!`);
    }
    if (parent_id === null && !edit) {
        errors.push(`Roditeljski meni je obavezno polje!`);
    }
    if (linkType === null && !edit) {
        errors.push(`Tip linka je obavezno polje!`);
    }

    return errors;
};
export const addProductCategoryValidation = (state, edit) => {
    const errors = [];
    const { name } = state;
    if (name === "") {
        errors.push(`Naziv je obavezno polje!`);
    }

    return errors;
};
export const addMenusValidation = (name, position) => {
    const errors = [];
    if (name === "") {
        errors.push(`Naziv je obavezno polje!`);
    }
    if (isNaN(position)) {
        errors.push(`Pozicija je obavezno polje!`);
    }
    return errors;
};

export const addGameValidation = (game) => {
    const errors = [];

    if (game.host === 0) {
        errors.push("Domaćin je obavezno polje!");
    }
    if (game.guest === 0) {
        errors.push("Gost je obavezno polje!");
    }
    if (!game.date) {
        errors.push("Datum je obavezno polje!");
    }
    if (game.competition === 0) {
        errors.push("Takmičenje je obavezno polje!");
    }
    if (game.location === "") {
        errors.push("Lokacija je obavezno polje!");
    }
    return errors;
};

export const addPlayerValidation = (player, edit, firstTeam) => {
    const errors = [];

    if (player.name === "") {
        errors.push("Ime je obavezno polje!");
    }
    if (firstTeam) {
        if (player.number === null) {
            errors.push("Broj je obavezno polje!");
        }
        if (player.position === "") {
            errors.push("Pozicija je obavezno polje!");
        }
        if (player.birth_date === "") {
            errors.push("Datum rođenja je obavezno polje!");
        }
        if (player.birth_place === "") {
            errors.push("Mjesto rođenja je obavezno polje!");
        }
        if (!player.height) {
            errors.push("Visina je obavezno polje!");
        }
        if (!player.weight) {
            errors.push("Težina je obavezno polje!");
        }
        if (player.nationality === "") {
            errors.push("Nacionalnost je obavezno polje!");
        }
        if (player.clubs === "") {
            errors.push("Klubovi je obavezno polje!");
        }
        if (!player.image && !edit) {
            errors.push("Fotografija je obavezno polje!");
        }
    }

    return errors;
};

export const addStaffValidation = (player, edit) => {
    const errors = [];

    if (player.name === "") {
        errors.push("Ime je obavezno polje!");
    }
    if (player.position === "") {
        errors.push("Pozicija je obavezno polje!");
    }
    if (player.birth_date === "") {
        errors.push("Datum rođenja je obavezno polje!");
    }
    if (player.birth_place === "") {
        errors.push("Mjesto rođenja je obavezno polje!");
    }

    if (player.nationality === "") {
        errors.push("Nacionalnost je obavezno polje!");
    }

    if (!player.image && !edit) {
        errors.push("Fotografija je obavezno polje!");
    }
    return errors;
};

export const addVideoValidation = (state) => {
    const errors = [];

    if (state.link === "") {
        errors.push("Link je obavezno polje!");
    }
    if (state.title === "") {
        errors.push("Naslov je obavezno polje!");
    }
    if (state.date === null || state.date === undefined) {
        errors.push("Datum je obavezno polje!");
    }
    return errors;
};

// language_id: 1,
//     title: "",
//     description: "",
//     content: "",
//     slug: ""
