import { BrowserRouter, Route } from "react-router-dom";
import { authenticateUser, loginUser } from "../functions/postFunctions";

import AddAlbum from "./albums/AddPage/AddAlbum";
import AddBlog from "./blogs/AddPage/AddBlog";
import AddNews from "./news/AddPage/AddNews";
import AddReservation from "./orders/AddReservation/AddReservation";
import AlbumsPage from "./albums";
import AnnouncementsPage from "./announcements";
import BlogPage from "./blogs";
import EditAlbum from "./albums/EditPage/EditAlbum";
import EditBlog from "./blogs/EditPage/EditBlog";
import EditNews from "./news/EditPage/EditNews";
import EditReservation from "./orders/AddReservation/EditReservation";
import Header from "../components/Header/Header";
import LibraryPage from "./library";
import Login from "./login/Login";
import MenusItems from "./menus/MenusItems/MenusItems";
import MenusPage from "./menus/MenusPage";
import Navbar from "../components/Navbar/Navbar";
import NewsPage from "./news";
import OrdersPage from "./orders";
import PressClippingsPage from "./pressClippings";
import React from "react";
import VideosPage from "./video/VideosPage";
import axios from "axios";
import EditMember from "./members/EditMember/EditMember";
import PartnersPage from "./partners/PartnersPage";
import CustomersPage from "./customers";
import TestimonialsPage from "./testimonials";
import AddTestimonial from "./testimonials/AddTestimonial/AddTestimonial";
import EditTestimonial from "./testimonials/EditTestimonial/EditTestimonial";
class App extends React.Component {
    state = {
        breadcrumbs: "Quantic CMS",
        loggedIn: false,
        token: "",
        errorMessage: "",
        role: null,
        member_id: 0,
    };

    setBreadcrumbs = (breadcrumbs) => {
        this.setState({ breadcrumbs });
    };

    componentDidMount() {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    this.setState({ token: "", loggedIn: false });
                    window.location = "/";
                }
                return error;
            }
        );
        if (
            localStorage.getItem("Authorization") &&
            localStorage.getItem("Authorization")
        ) {
            this.setState({
                loggedIn: true,
                token: localStorage.getItem("Authorization"),
                role: parseInt(localStorage.getItem("Role")),
                member_id: parseInt(localStorage.getItem("MemberID")),
            });
        }
    }

    clearMessage = () => {
        this.setState({ errorMessage: "" });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.token !== this.state.token) {
            authenticateUser(
                localStorage.getItem("Authorization")
            ).then((res) => {});
        }
    }

    handleLogin = (username, password) => {
        loginUser(username, password).then((res) => {
            if (res.success) {
                localStorage.setItem(
                    "Authorization",
                    `Bearer ${res.access_token}`
                );
                localStorage.setItem("Role", res.user.role);
                localStorage.setItem("MemberID", res.user.member_id);
                this.setState({
                    token: `Bearer ${res.access_token}`,
                    loggedIn: true,
                    role: res.user.role,
                    member_id: res.user.member_id,
                });
                if (res.user.role === 1) {
                    window.location = `/${res.user.member_id}`;
                }
            } else {
                this.setState({
                    errorMessage:
                        "Korisničko ime ili lozinka nijesu tačni. Molimo unesite ispravne podatke.",
                });
            }
        });
    };

    render() {
        const { loggedIn, token, errorMessage, role, member_id } = this.state;
        if (loggedIn && role === 0) {
            return (
                <BrowserRouter basename="/">
                    <Navbar />
                    <Route
                        path="/"
                        render={(props) => (
                            <Header
                                {...props}
                                breadcrumbs={this.state.breadcrumbs}
                            />
                        )}
                    />
                    <main className="px-40 pt-20 pb-40">
                        <div className="content">
                            <Route
                                exact
                                path="/(meniji|menus)"
                                render={(props) => (
                                    <MenusPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        token={token}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/(meniji|menus)/:id"
                                render={(props) => (
                                    <MenusItems
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        token={token}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/"
                                render={(props) => (
                                    <NewsPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        token={token}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/dodaj-novost"
                                render={(props) => (
                                    <AddNews
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/izmjeni-novost/:id"
                                render={(props) => (
                                    <EditNews
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/blog"
                                render={(props) => (
                                    <BlogPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        token={token}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/dodaj-blog"
                                render={(props) => (
                                    <AddBlog
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/izmjeni-blog/:id"
                                render={(props) => (
                                    <EditBlog
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/fotografije"
                                render={(props) => (
                                    <AlbumsPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        token={token}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/dodaj-album"
                                render={(props) => (
                                    <AddAlbum
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/izmjeni-album/:id"
                                render={(props) => (
                                    <EditAlbum
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/video"
                                render={(props) => (
                                    <VideosPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/(dogadjaji|orders)"
                                render={(props) => (
                                    <OrdersPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/(dodaj-dogadjaj|orders)"
                                render={(props) => (
                                    <AddReservation
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/izmjeni-dogadjaj/:id"
                                render={(props) => (
                                    <EditReservation
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/testimonials"
                                render={(props) => (
                                    <TestimonialsPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/dodaj-testimonial"
                                render={(props) => (
                                    <AddTestimonial
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/izmijeni-testimonial/:id"
                                render={(props) => (
                                    <EditTestimonial
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/izmjeni-clana/:id"
                                render={(props) => (
                                    <EditMember
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/saopstenja"
                                render={(props) => (
                                    <AnnouncementsPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/press-clippings"
                                render={(props) => (
                                    <PressClippingsPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/dokumenti"
                                render={(props) => (
                                    <LibraryPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                        type={0}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/publikacije"
                                render={(props) => (
                                    <LibraryPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                        type={1}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/baneri"
                                render={(props) => (
                                    <PartnersPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                        type={1}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/partneri"
                                render={(props) => (
                                    <PartnersPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                        type={0}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/zaposleni"
                                render={(props) => (
                                    <CustomersPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                        type={0}
                                    />
                                )}
                            />
                        </div>
                    </main>
                </BrowserRouter>
            );
        } else if (loggedIn && role === 1) {
            return (
                <BrowserRouter basename="/">
                    <Navbar user customer={member_id} />
                    <Route
                        path="/"
                        render={(props) => (
                            <Header
                                {...props}
                                breadcrumbs={this.state.breadcrumbs}
                            />
                        )}
                    />
                    <main className="px-40 pt-20 pb-40">
                        <div className="content">
                            <Route
                                exact
                                path="/(meniji|menus)"
                                render={(props) => (
                                    <MenusPage
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        token={token}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/:id"
                                render={(props) => (
                                    <EditMember
                                        {...props}
                                        breadcrumbs={this.state.breadcrumbs}
                                        setBreadcrumbs={this.setBreadcrumbs}
                                        token={token}
                                        customer={this.state.member_id}
                                    />
                                )}
                            />
                        </div>
                    </main>
                </BrowserRouter>
            );
        } else {
            return (
                <BrowserRouter basename="/">
                    <Route
                        path="/"
                        render={(props) => (
                            <Login
                                {...props}
                                breadcrumbs={this.state.breadcrumbs}
                                handleLogin={this.handleLogin}
                                errorMessage={errorMessage}
                                clearMessage={this.clearMessage}
                            />
                        )}
                    />
                </BrowserRouter>
            );
        }
    }
}

export default App;
