import React, { Component } from "react";

import { CustomButton } from "../../components/Buttons/Button";
import { CustomInput } from "../../components/Form/Inputs/Inputs";
import { addCompetitionValidation } from "../../functions/validateFunctions";
import { editPartner, addDocument } from "../../functions/postFunctions";

class EditDocument extends Component {
    state = {
        id: 0,
        title: "",
        document: null,
        cover: null,
    };
    componentDidMount() {
        const { documentEdit } = this.props;
        this.setState({
            title: documentEdit.title,
            id: documentEdit.id,
            document: documentEdit.file,
            cover: documentEdit.cover,
        });
    }
    // componentDidUpdate(prevProps) {
    //     if (
    //         prevProps.documentEdit !== this.props.documentEdit &&
    //         this.props.documentEdit
    //     ) {
    //         const { documentEdit } = this.props;
    //         this.setState({
    //             title: documentEdit.title,
    //             id: documentEdit.id,
    //             document: documentEdit.file,
    //             cover: documentEdit.cover,
    //         });
    //     }
    // }
    handleInput = (name, value) => {
        this.setState({ [name]: value });
    };

    handleFileInput = (event) => {
        this.setState({ logoEdit: [...event] });
    };

    handleSubmit = () => {
        const { title, document, cover } = this.state;

        const data = new FormData();

        data.append("title", title);
        data.append("file", document);
        data.append("type", this.props.type);

        if (this.props.type === 1) {
            data.append("cover", cover);
        }

        this.setState({ popupShown: true });
        addDocument(data, this.props.token).then((res) => {
            if (res.success) {
                this.setState(({ pages }) => ({
                    pages: [res.document, ...pages],
                    title: "",
                    date: new Date(),
                    document: null,
                    popupShown: false,
                    cover: null,
                }));
            } else {
                this.setState({ popupShown: false });
            }
        });
    };

    removePopup = () => {
        this.setState({
            showErrorPopup: false,
            errors: [],
            responseErrors: [],
        });
    };

    render() {
        const { document, title, cover } = this.state;
        const { visible, hideModal } = this.props;
        console.log(this.props.documentEdit);
        return (
            <div
                className={`addTeamModal d-flex justify-content-center align-items-center ${
                    visible ? "visible" : ""
                }`}
            >
                <div className="addTeamContent py-30 px-30">
                    <h4 className="text-color-primary f-s-20 lh text-center mb-20">
                        {this.props.type === 0
                            ? "Izmijeni dokument"
                            : "Izmijeni publikaciju"}{" "}
                    </h4>

                    <div className="row mb-30">
                        <div className="col-12">
                            <CustomInput
                                label="Naziv"
                                value={title}
                                handleChange={this.handleInput}
                                name="title"
                                index={0}
                                counter={false}
                            />
                        </div>
                    </div>

                    <div className="row mt-40">
                        <div className="col-lg-12 galleryWrapper">
                            <CustomInput
                                label="Izaberi fajl"
                                value={document}
                                handleChange={this.handleFileInput}
                                name="document"
                                index={0}
                                type="file"
                            />
                            {this.state.document ? (
                                <div className="selectedImages my-20 py-10 px-15">
                                    <h3 className="f-s-16 text-color-primary f-w-4">
                                        Fajl odabran za upload:
                                    </h3>
                                    <p className="mt-20">
                                        {this.state.document}
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {this.props.type === 1 && (
                        <div className="row mt-30">
                            <div className="col-4 d-flex align-items-center">
                                <CustomInput
                                    label="Izaberi cover"
                                    value={cover}
                                    handleChange={this.handleFileInput}
                                    name="cover"
                                    index={0}
                                    type="file"
                                    className="mt-40"
                                    temp="cover"
                                    subMessage="(512x512)"
                                />
                                <div className="galleryWrapper">
                                    {this.state.cover ? (
                                        <div className="selectedImages ml-30 py-10 px-15">
                                            <h3 className="f-s-16 text-color-primary f-w-4">
                                                Fotografija odabrana za upload:
                                            </h3>
                                            <p className="mt-20">
                                                {this.state.cover}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row mt-40">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <CustomButton
                                className="mr-30"
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                {this.props.type === 0
                                    ? "                                Izmijeni dokument"
                                    : "Izmijeni publikaciju"}{" "}
                            </CustomButton>
                            <CustomButton className="red" onClick={hideModal}>
                                Odustani
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditDocument;
