import { Constants } from "../services/constants.service";
import axios from "axios";

let server = "https://api.economicteam.me/api";

// if (process.env.NODE_ENV === "development") {
//     server = "https://economicapi.quanticstudios.io/api";
// } else {
//     server = "https://admin.mmcg.me/api";
// }

export const authenticateUser = (token) => {
    return axios({
        method: "post",
        url: `${server}/auth/authenticate`,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const getAllProductsForSorting = (category_id) => {
    return axios({
        method: "post",
        url: `${server}/products/getAllProductsForSorting`,
        data: { category_id },
    }).then((res) => res.data);
};

export const changeReservationStatus = (id, active, token) => {
    return axios({
        method: "post",
        url: `${server}/reservations/statusChange`,
        data: {
            id,
            active,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const loginUser = (username, password) => {
    return axios({
        method: "post",
        url: `${server}/login`,
        data: {
            username,
            password,
        },
    }).then((res) => res.data);
};
export const createCustomer = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/auth/createCustomer`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteCustomer = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/auth/deleteCustomer`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addPage = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/pages/addPage`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addMember = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/members/createMember`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addTestimonial = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/testimonials/addTestimonial`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const updateTestimonial = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;

    return axios({
        method: "post",
        url: `${server}/testimonials/updateTestimonial`,
        data,
        onUploadProgress,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const updateMember = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/members/updateMember`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addReservation = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/reservations/createReservation`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addAnnouncement = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/announcements/addAnnouncement`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addDocument = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/library/addDocument`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addPressClipping = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/pressClippings/addPressClipping`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const updateReservation = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/reservations/updateReservation`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const getHours = (date) => {
    return axios({
        method: "post",
        url: `${server}/reservations/getHours`,
        data: { date },
    }).then((res) => res.data);
};
export const addProduct = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/products/addProduct`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addImages = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/pages/addImages`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addImagesProduct = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/products/addImages`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const updatePage = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/pages/updatePage`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const updateProduct = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/products/updateProduct`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const getAllPages = (language_id, limit, offset, category_id) => {
    return axios({
        method: "post",
        url: `${server}/pages/getAllPages`,
        data: {
            language_id,
            limit,
            offset,
            category_id,
        },
    }).then((res) => res.data);
};

export const getAllAnnouncements = () => {
    return axios({
        method: "post",
        url: `${server}/announcements/getAllAnnouncements`,
    }).then((res) => res.data);
};
export const getAllUsers = () => {
    return axios({
        method: "post",
        url: `${server}/getAllUsers`,
    }).then((res) => res.data);
};

export const getAllDocuments = () => {
    return axios({
        method: "post",
        url: `${server}/library/getAllDocuments`,
    }).then((res) => res.data);
};
export const getAllPressClippings = () => {
    return axios({
        method: "post",
        url: `${server}/pressClippings/getAllPressClippings`,
    }).then((res) => res.data);
};

export const getAllProducts = (limit, offset, category_id) => {
    return axios({
        method: "post",
        url: `${server}/products/getAllProducts`,
        data: {
            limit,
            offset,
            category_id,
        },
    }).then((res) => res.data);
};

export const getPage = (id) => {
    return axios({
        method: "post",
        url: `${server}/pages/getPage`,
        data: {
            id,
            admin: true,
        },
    }).then((res) => res.data);
};
export const getTestimonial = (id) => {
    return axios({
        method: "post",
        url: `${server}/testimonials/getTestimonial`,
        data: {
            id,
            admin: true,
        },
    }).then((res) => res.data);
};
export const getProduct = (id) => {
    return axios({
        method: "post",
        url: `${server}/products/getProduct`,
        data: {
            id,
            admin: true,
        },
    }).then((res) => res.data);
};

export const deletePage = (page_id, token) => {
    return axios({
        method: "post",
        url: `${server}/pages/deletePage`,
        data: {
            page_id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deleteAnnouncement = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/announcements/deleteAnnouncement`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteDocument = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/library/deleteDocument`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deletePressClipping = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/pressClippings/deletePressClipping`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteFile = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/files/deleteFile`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteProduct = (product_id, token) => {
    return axios({
        method: "post",
        url: `${server}/products/deleteProduct`,
        data: {
            product_id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteOrder = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/reservations/deleteReservation`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteMember = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/members/deleteMember`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deleteTestimonial = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/testimonials/deleteTestimonial`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deleteImage = (image, image_id, token, page) => {
    return axios({
        method: "post",
        url: `${server}/${page || "pages"}/deleteImage`,
        data: {
            image,
            image_id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const sortImages = (images, token) => {
    return axios({
        method: "post",
        url: `${server}/pages/sortImages`,
        data: {
            images,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const sortMenus = (menus, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/sortMenus`,
        data: {
            menus,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const sortCategories = (categories, token) => {
    return axios({
        method: "post",
        url: `${server}/products/sortCategories`,
        data: {
            categories,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const sortProducts = (products, token) => {
    return axios({
        method: "post",
        url: `${server}/products/sortProducts`,
        data: {
            products,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const getAllTeams = () => {
    return axios({
        method: "post",
        url: `${server}/teams/getAllTeams`,
    }).then((res) => res.data);
};
export const getAllCategories = () => {
    return axios({
        method: "post",
        url: `${server}/players/getAllCategories`,
    }).then((res) => res.data);
};
export const getAllCategoriesPage = () => {
    return axios({
        method: "post",
        url: `${server}/pages/allCategories`,
    }).then((res) => res.data);
};
export const getReservation = (id) => {
    return axios({
        method: "post",
        data: { id },
        url: `${server}/reservations/getReservation`,
    }).then((res) => res.data);
};
export const getAllVideos = () => {
    return axios({
        method: "post",
        url: `${server}/videos/getAllVideos`,
    }).then((res) => res.data);
};
export const getAllCompetitions = () => {
    return axios({
        method: "post",
        url: `${server}/competitions/getAllCompetitions`,
    }).then((res) => res.data);
};

export const getAllMenus = () => {
    return axios({
        method: "post",
        url: `${server}/menus/getAllMenus`,
    }).then((res) => res.data);
};

export const addTeam = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/teams/addTeam`,
        data,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addVideo = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/videos/addVideo`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addCompetition = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/competitions/addCompetition`,
        data,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addMenuItem = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/addMenuItem`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addProductCategory = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/products/addProductCategory`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editMenuItem = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/editMenuItem`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editProductCategory = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/products/editProductCategory`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editCompetition = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/competitions/editCompetition`,
        data,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const editTeam = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/teams/editTeam`,
        data,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editVideo = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/videos/editVideo`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addGame = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/games/addGame`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editGame = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/games/editGame`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const getAllGames = () => {
    return axios({
        method: "post",
        url: `${server}/games/getAllGames`,
    }).then((res) => res.data);
};
export const getGame = (id) => {
    return axios({
        method: "post",
        url: `${server}/games/getGame`,
        data: {
            id,
        },
    }).then((res) => res.data);
};

export const deleteTeam = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/teams/deleteTeam`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteMenuItem = (id, menu_id, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/deleteMenuItem`,
        data: {
            id,
            menu_id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteProductCategory = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/products/deleteProductCategory`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deleteVideo = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/videos/deleteVideo`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteMenu = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/deleteMenu`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteGame = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/games/deleteGame`,
        data: {
            id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addPlayer = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/players/addPlayer`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addPlayerCategory = (name, token) => {
    return axios({
        method: "post",
        url: `${server}/players/addCategory`,
        data: { name },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addMenu = (name, position, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/addMenu`,
        data: { name, position },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editMenu = (name, position, id, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/editMenu`,
        data: { name, position, id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const addPageCategory = (name, token) => {
    return axios({
        method: "post",
        url: `${server}/pages/addCategory`,
        data: { name },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deletePlayerCategory = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/players/deleteCategory`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deletePageCategory = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/pages/deleteCategory`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editPlayerCategory = (id, name, token) => {
    return axios({
        method: "post",
        url: `${server}/players/editCategory`,
        data: { id, name },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const editPageCategory = (id, name, token) => {
    return axios({
        method: "post",
        url: `${server}/pages/editCategory`,
        data: { id, name },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addStaff = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/staff/addStaff`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const updateStaff = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/staff/updateStaff`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const updatePlayer = (data, token, config) => {
    const onUploadProgress = config.onUploadProgress;
    return axios({
        method: "post",
        url: `${server}/players/updatePlayer`,
        data,
        onUploadProgress,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const getAllPlayers = (category) => {
    return axios({
        method: "post",
        data: { category },
        url: `${server}/players/getAllPlayers`,
    }).then((res) => res.data);
};
export const getAllStaff = () => {
    return axios({
        method: "post",
        url: `${server}/staff/getAllStaff`,
    }).then((res) => res.data);
};

export const getPlayer = (id) => {
    return axios({
        method: "post",
        url: `${server}/players/getPlayer`,
        data: { id },
    }).then((res) => res.data);
};
export const getAllMenuItems = (id) => {
    return axios({
        method: "post",
        url: `${server}/menus/getAllMenuItems`,
        data: { id },
    }).then((res) => res.data);
};
export const getAllProductCategory = () => {
    return axios({
        method: "post",
        url: `${server}/products/getAllProductCategory`,
    }).then((res) => res.data);
};
export const getAllOrders = () => {
    return axios({
        method: "post",
        url: `${server}/reservations/getAllReservations`,
    }).then((res) => res.data);
};
export const getAllMembers = () => {
    return axios({
        method: "post",
        url: `${server}/members/getAllMembers`,
    }).then((res) => res.data);
};

export const getAllTestimonials = () => {
    return axios({
        method: "post",
        url: `${server}/testimonials/getAllTestimonials`,
    }).then((res) => res.data);
};
export const getMember = (id) => {
    return axios({
        method: "post",
        data: { id },
        url: `${server}/members/getMember`,
    }).then((res) => res.data);
};
export const getOrder = (id) => {
    return axios({
        method: "post",
        data: { id },
        url: `${server}/orders/getOrder`,
    }).then((res) => res.data);
};

export const getAllParentMenus = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/menus/getAllParentMenus`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const getStaff = (id) => {
    return axios({
        method: "post",
        url: `${server}/staff/getSingleStaff`,
        data: { id },
    }).then((res) => res.data);
};
export const getTable = (competition_id) => {
    return axios({
        method: "post",
        url: `${server}/tables/getTable`,
        data: { competition_id },
    }).then((res) => res.data);
};

export const deletePlayer = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/players/deletePlayer`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteStaff = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/staff/deleteStaff`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const deleteFromTable = (id, competition_id, token) => {
    return axios({
        method: "post",
        url: `${server}/tables/deleteFromTable`,
        data: { id, competition_id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const updateTable = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/tables/updateTable`,
        data,
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deleteCompetition = (id, token) => {
    return axios({
        method: "post",
        url: `${server}/competitions/deleteCompetition`,
        data: { id },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const addTeamToTables = (team_id, competition_id, token) => {
    return axios({
        method: "post",
        url: `${server}/tables/addTeam`,
        data: {
            team_id,
            competition_id,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const deletePartner = (id, type, token) => {
    return axios({
        method: "post",
        url: `${server}/partners/deletePartner`,
        data: { id, type },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const sortPartners = (partners, type, token) => {
    return axios({
        method: "post",
        url: `${server}/partners/sortPartners`,
        data: {
            partners,
            type,
        },
        headers: {
            Authorization: token,
        },
    }).then((res) => res.data);
};
export const getAllPartners = (type) => {
    return axios({
        method: "post",
        data: { type },
        url: `${server}/partners/getAllPartners`,
    }).then((res) => res.data);
};

export const addPartner = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/partners/addPartner`,
        data,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};

export const editPartner = (data, token) => {
    return axios({
        method: "post",
        url: `${server}/partners/editPartner`,
        data,
        headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
        },
    }).then((res) => res.data);
};
