import React, { Component } from "react";

import { CustomButton } from "../../components/Buttons/Button";
import { CustomLoginInput } from "../../components/Form/Inputs/Inputs";
import Logo from "../../components/Navbar/Logo/Logo";

class Login extends Component {
  state = {
    username: "",
    password: "",
    showPopup: false
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { username, password } = this.state;
    this.props.handleLogin(username, password);
    // this.setState({ username: "", password: "" });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.errorMessage !== this.props.errorMessage &&
      this.props.errorMessage !== ""
    ) {
      this.setState({ showPopup: true });
    }
  }

  removePopup = () => {
    this.setState({ showPopup: false });
    this.props.clearMessage();
  };

  render() {
    return (
      <div
        id="loginPage"
        className="container d-flex align-items-center justify-content-center flex-column"
      >
        <div
          className={` errorPopup ${this.state.showPopup ? "popupShown" : ""}`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-22 f-w-4 text-center text-color-red">Greška</h3>
            <h5 className="f-s-16 f-w-4 text-center">
              {this.props.errorMessage}
            </h5>
            <div className="button text-center mt-30">
              <CustomButton onClick={this.removePopup}>U redu</CustomButton>
            </div>
          </div>
        </div>
        <form onSubmit={e => e.preventDefault()}>
          <div className="logoContainer text-center">
            <Logo />
          </div>
          <CustomLoginInput
            label="Korisničko ime"
            value={this.state.username}
            handleChange={this.handleInput}
            name="username"
            index={0}
            className="mt-20"
          />
          <CustomLoginInput
            label="Lozinka"
            value={this.state.password}
            handleChange={this.handleInput}
            name="password"
            index={0}
            type="password"
            className="mt-20"
          />
          <div className="buttonContainer text-center">
            <CustomButton onClick={this.handleSubmit} className="mt-20 mx-auto">
              Uloguj se
            </CustomButton>
          </div>
          <div className="copyright text-center mt-20">
            <span className="text-color-font-l">
              <i>Powered by</i> <b>Quantic</b>
            </span>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
