import React, { Component } from "react";

import { Jodit } from "jodit";
import JoditEditor from "jodit-react";

class TextEditor extends Component {
  handleCounter = val => {
    document.getElementById(
      `${this.props.name}-${this.props.index}`
    ).innerHTML = val;
  };
  config = {
    readonly: false,
    buttons:
      "source,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,,paragraph,,,image,video,link,,align,undo,redo",
    spellcheck: false,
    // https://upload.wikimedia.org/wikipedia/commons/6/6d/Windows_Settings_app_icon.png
    showXPathInStatusbar: false,
    // defaultActionOnPaste: "insert_only_text",
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    showCharsCounter: false,
    showWordsCounter: false,
    placeholder: "",
    colorPickerDefaultTab: "color",
    enableDragAndDropFileToEditor: true,
    uploader: { insertImageAsBase64URI: true },
    colors: {
      greyscale: [
        "#2f3c4a",
        "#a8a8a8",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF"
      ],
      palette: [
        "#262364",
        "#246fb4",
        "#fcb040",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF"
      ],
      full: [
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#DD7E6B",
        "#EA9999",
        "#F9CB9C",
        "#FFE599",
        "#B6D7A8",
        "#A2C4C9",
        "#A4C2F4",
        "#9FC5E8",
        "#B4A7D6",
        "#D5A6BD",
        "#CC4125",
        "#E06666",
        "#F6B26B",
        "#FFD966",
        "#93C47D",
        "#76A5AF",
        "#6D9EEB",
        "#6FA8DC",
        "#8E7CC3",
        "#C27BA0",
        "#A61C00",
        "#CC0000",
        "#E69138",
        "#F1C232",
        "#6AA84F",
        "#45818E",
        "#3C78D8",
        "#3D85C6",
        "#674EA7",
        "#A64D79",
        "#85200C",
        "#990000",
        "#B45F06",
        "#BF9000",
        "#38761D",
        "#134F5C",
        "#1155CC",
        "#0B5394",
        "#351C75",
        "#733554",
        "#5B0F00",
        "#660000",
        "#783F04",
        "#7F6000",
        "#274E13",
        "#0C343D",
        "#1C4587",
        "#073763",
        "#20124D",
        "#4C1130"
      ]
    }
  };

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.image = {
      exec: editor => {
        const el = document.querySelector("#images");
        if (el) {
          el.click();
        }
      }
    };
  };

  componentDidMount() {
    this.uploadImageButton();
  }

  render() {
    return (
      <div className="textEditor f-s-16">
        <JoditEditor
          value={this.props.value}
          config={this.config}
          tabIndex={1}
          onBlur={newContent => {}}
          onChange={newContent => {
            this.handleCounter(newContent.length);
            this.props.handleInput(
              this.props.name,
              newContent,
              this.props.index
            );
          }}
          on
        />
        <span className="count f-s-10 px-5">
          Broj karaktera:{" "}
          <b id={`${this.props.name}-${this.props.index}`}>
            {this.props.value ? this.props.value.length : 0}
          </b>
        </span>
      </div>
    );
  }
}
export default TextEditor;
