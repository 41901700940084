import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
    addAnnouncement,
    deleteAnnouncement,
    getAllAnnouncements,
    getAllPages,
} from "../../functions/postFunctions";
import {
    dateLocalisation,
    dateLocalisationShort,
} from "../../functions/functions";

import { CustomInput } from "../../components/Form/Inputs/Inputs";
import DatePicker from "react-date-picker";
import Table from "../../components/Table/Table";
import { Constants } from "../../services/constants.service";

class AnnouncementsPage extends Component {
    state = {
        theads: ["Naslov", "Dokument", "Datum", "Akcije"],
        pages: [],
        removeLoadMore: true,
        page: 1,
        categoryList: [],
        currentCategory: 1,
        title: "",
        date: new Date(),
        document: null,
        popupShown: false,
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Saopštenja");
        this.loadNews();
    }
    handleDeletePage = (id) => {
        const { pages } = this.state;
        const newPages = pages.filter((e) => e.id !== id);

        deleteAnnouncement(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ pages: newPages });
            }
        });
    };

    loadNews = () => {
        getAllAnnouncements().then((res) => {
            if (res.success) {
                this.setState({
                    pages: res.announcements,
                    page: 1,
                    removeLoadMore: res.announcements.length < 20,
                });
            }
        });
    };

    loadMoreNews = () => {
        const { page, currentCategory } = this.state;
        getAllPages(1, 20, page * 20, currentCategory.value).then((res) => {
            if (res.success) {
                this.setState(({ pages, page }) => ({
                    pages: [...pages, ...res.pages],
                    page: page + 1,
                    removeLoadMore: res.pages.length < 20,
                }));
            }
        });
    };

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleAddAnnouncement = () => {
        const { title, date, document } = this.state;

        const data = new FormData();

        const formattedDate = dateLocalisationShort(date, "en-GB").replaceAll(
            "/",
            "."
        );

        data.append("title", title);
        data.append("date", formattedDate);
        data.append("document", document);

        this.setState({ popupShown: true });
        addAnnouncement(data, this.props.token).then((res) => {
            if (res.success) {
                this.setState({
                    pages: res.announcements,
                    title: "",
                    date: new Date(),
                    document: null,
                    popupShown: false,
                });
            } else {
                this.setState({ popupShown: false });
            }
        });
    };

    handleFileInput = (event) => {
        this.setState({ document: event[0] });
    };

    render() {
        const { theads, pages, categoryList, currentCategory } = this.state;
        let server = Constants.SERVER;
        // if (process.env.NODE_ENV === "development") {
        //   server = "http://127.0.0.12";
        // } else {
        //   server = "https://admin.mmcg.me";
        // }

        return (
            <div>
                <div className="wrapper">
                    <div
                        className={`errorPopup ${
                            this.state.popupShown ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                                Kreiranje saopštenja u toku
                            </h3>
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.popupShown && (
                    <div className="pageTop mb-40">
                        <div className="row">
                            <div className="col-lg-3">
                                <CustomInput
                                    label="Naslov"
                                    value={this.state.title}
                                    handleChange={this.handleInput}
                                    name="title"
                                />
                            </div>
                            <div className="col-lg-3">
                                <DatePicker
                                    onChange={(e) =>
                                        this.handleInput("date", e)
                                    }
                                    value={this.state.date}
                                    locale={"sr-Latn-ME"}
                                />
                            </div>
                            <div className="col-2">
                                <CustomInput
                                    label="Izaberi fajl"
                                    value={null}
                                    handleChange={this.handleFileInput}
                                    name="document"
                                    index={0}
                                    type="file"
                                    className="mt-40"
                                    temp="files"
                                />
                                <div className="galleryWrapper">
                                    {this.state.document ? (
                                        <div className="selectedImages my-20 py-10 px-15">
                                            <h3 className="f-s-16 text-color-primary f-w-4">
                                                Fajl odabran za upload:
                                            </h3>
                                            <p className="mt-20">
                                                {this.state.document.name}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <CustomButton
                                    className="green"
                                    onClick={this.handleAddAnnouncement}
                                >
                                    Dodaj saopštenje
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )}
                <Table theads={theads}>
                    {pages.map((e, index) => {
                        return (
                            <tr key={index}>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.title}</span>
                                </td>
                                <td className="td px-10">
                                    <a
                                        href={`https://economicteam.me//${e.document}`}
                                        className="f-s-16 transition-1 py-5 br-r-5 text-color-primary regular-link"
                                        rel="noreferrer noopener"
                                        download
                                    >
                                        Preuzmi dokument
                                    </a>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        {dateLocalisationShort(e.date)}
                                    </span>
                                </td>
                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    {/* <LinkButton href={`izmjeni-vijest/${e.id}`} className="mr-20">
                    Izmjeni
                  </LinkButton> */}
                                    <CustomButton
                                        onClick={() =>
                                            this.handleDeletePage(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                {!this.state.removeLoadMore ? (
                    <div className="wrapper mt-40 d-flex justify-content-center">
                        <CustomButton onClick={this.loadMoreNews}>
                            Učitaj još
                        </CustomButton>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default AnnouncementsPage;
