import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
    createCustomer,
    deleteAnnouncement,
    deleteCustomer,
    getAllAnnouncements,
    getAllMembers,
    getAllPages,
    getAllUsers,
} from "../../functions/postFunctions";
import { dateLocalisationShort } from "../../functions/functions";

import { CustomInput } from "../../components/Form/Inputs/Inputs";
import Table from "../../components/Table/Table";
import SearchableDropdown from "../../components/Form/Dropdown/SearchableDropdown";
import { addUserValidation } from "../../functions/validateFunctions";

class CustomersPage extends Component {
    state = {
        theads: ["Ime zaposlenog", "Akcije"],
        pages: [],
        removeLoadMore: true,
        page: 1,
        username: "",
        popupShown: false,
        title_mne: "",
        title_en: "",
        members: [],
        errors: [],
        errorPopupShown: false,
        chosenMember: null,
        image: null,
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Zaposleni");
        this.loadNews();

        getAllMembers().then((res) => {
            if (res.success) {
                this.setState({
                    members: res.members.map((e) => ({
                        value: e.id,
                        label: e.name,
                    })),
                });
            }
        });
    }

    handleDeletePage = (id) => {
        const { pages } = this.state;
        const newPages = pages.filter((e) => e.id !== id);

        deleteCustomer(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ pages: newPages });
            }
        });
    };

    loadNews = () => {
        getAllUsers().then((res) => {
            if (res.success) {
                this.setState({
                    pages: res.users,
                    page: 1,
                    removeLoadMore: true,
                });
            }
        });
    };

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleAddAnnouncement = () => {
        const { username, title_mne, title_en, image } = this.state;
        const errors = addUserValidation({
            username,
            title_mne,
            title_en,
            image,
        });

        if (Object.keys(errors).length > 0 && errors.constructor === Object) {
            this.setState({ errors, errorPopupShown: true });
        } else {
            const data = {
                username,
                title_mne,
                title_en,
                image,
            };
            this.setState({ popupShown: true });
            createCustomer(data, this.props.token).then((res) => {
                if (res.success) {
                    this.setState({
                        popupShown: false,
                        pages: res.users,
                    });
                } else {
                    this.setState({
                        popupShown: false,
                        errors: res.errors,
                        errorPopupShown: true,
                    });
                }
            });
        }
    };

    handleFileInput = (event) => {
        this.setState({ image: event[0] });
    };

    removePopup = () => {
        this.setState({ errors: [], errorPopupShown: false });
    };

    renderErrorPopup = () => {
        return (
            <div className="contentWrapper">
                <div
                    className={`errorPopup ${
                        this.state.errorPopupShown ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-18 f-w-4 uppercase text-center">
                            Problemi prilikom kreiranja zaposlenog
                        </h3>
                        <ul className="mt-30">
                            {Object.keys(this.state.errors).map((e, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="f-s-16 text-center"
                                    >
                                        {this.state.errors[e]}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="button text-center mt-30">
                            <CustomButton onClick={this.removePopup}>
                                Zatvori
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { theads, pages, members } = this.state;
        return (
            <div>
                {this.renderErrorPopup()}
                <div className="wrapper">
                    <div
                        className={`errorPopup ${
                            this.state.popupShown ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                                Kreiranje korisnika u toku
                            </h3>
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.popupShown && (
                    <div className="pageTop mb-40">
                        <div className="row">
                            <div className="col-lg-2">
                                <CustomInput
                                    label="Ime zaposlenog"
                                    value={this.state.username}
                                    handleChange={this.handleInput}
                                    name="username"
                                />
                            </div>
                            <div className="col-lg-2">
                                <CustomInput
                                    label="Titula MNE"
                                    value={this.state.title_mne}
                                    handleChange={this.handleInput}
                                    name="title_mne"
                                    type="string"
                                />
                            </div>
                            <div className="col-lg-2">
                                <CustomInput
                                    label="Titula ENG"
                                    value={this.state.title_en}
                                    handleChange={this.handleInput}
                                    name="title_en"
                                    type="string"
                                />
                            </div>
                            <div className="col-2">
                                <CustomInput
                                    label="Izaberi fotografiju"
                                    value={this.state.image}
                                    handleChange={this.handleFileInput}
                                    name="image"
                                    index={0}
                                    type="file"
                                />
                                <div className="galleryWrapper">
                                    {this.state.image ? (
                                        <div className="selectedImages my-20 py-10 px-15">
                                            <h3 className="f-s-16 text-color-primary f-w-4">
                                                Fotografija odabrana za upload:
                                            </h3>
                                            <p className="mt-20">
                                                {this.state.image.name}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <CustomButton
                                    className="green"
                                    onClick={this.handleAddAnnouncement}
                                >
                                    Kreiraj korisnika
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )}
                <Table theads={theads}>
                    {pages.map((e, index) => {
                        return (
                            <tr key={index}>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.username}</span>
                                </td>
                                {/* <td className="td px-10">
                                    <span className="f-s-16">
                                        {e.member_name}
                                    </span>
                                </td> */}
                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    {/* <LinkButton href={`izmjeni-vijest/${e.id}`} className="mr-20">
                    Izmjeni
                  </LinkButton> */}
                                    <CustomButton
                                        onClick={() =>
                                            this.handleDeletePage(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                {!this.state.removeLoadMore ? (
                    <div className="wrapper mt-40 d-flex justify-content-center">
                        <CustomButton onClick={this.loadMoreNews}>
                            Učitaj još
                        </CustomButton>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default CustomersPage;
