import React, { Component } from "react";
import { deletePartner, getAllPartners } from "../../functions/postFunctions";
import { CustomButton } from "../../components/Buttons/Button";

import AddPartner from "../../components/AddPartner/AddPartner";
import EditPartner from "../../components/EditPartner/EditPartner";
import SortableListPartners from "../../components/SortableGrid/SortableListPartners";

class PartnersPage extends Component {
    state = {
        theads: ["Logo", "Link", "Akcije"],
        partners: [],
        showAddCompetition: false,
        showEditCompetition: false,
        competitionForEdit: null,
        warningModal: false,
        deletionID: 0,
    };

    componentDidMount() {
        this.props.setBreadcrumbs(
            this.props.type === 0 ? "Partneri" : "Baneri"
        );
        getAllPartners(this.props.type).then((res) =>
            this.setState({ partners: res.partners })
        );
    }

    handleDeletePage = () => {
        const id = this.state.deletionID;

        const { partners } = this.state;
        const newPartners = partners.filter((e) => e.id !== id);
        this.setState({ warningModal: false, deletionID: 0 });
        deletePartner(id, this.props.type, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ partners: newPartners });
            }
        });
    };

    showModal = (name, id) => {
        let partner;
        if (id) {
            const { partners } = this.state;
            partner = partners.find((e) => e.id === id);
        }
        this.setState({ [name]: true, competitionForEdit: partner });
    };

    hideModal = () => {
        this.setState({
            showAddCompetition: false,
            showEditCompetition: false,
            competitionForEdit: null,
        });
    };

    addCompetitionToList = (partner) => {
        this.setState((prevState) => ({
            partners: [...prevState.partners, partner],
            showAddCompetition: false,
        }));
    };

    refreshList = () => {
        getAllPartners(this.props.type).then((res) =>
            this.setState({
                partners: res.partners,
                showEditCompetition: false,
                competitionsForEdit: null,
            })
        );
    };
    showDeleteModal = (id) => {
        this.setState({ warningModal: true, deletionID: id });
    };

    removePopup = () => {
        this.setState({ warningModal: false, deletionID: 0 });
    };
    render() {
        const { partners, competitionForEdit } = this.state;
        const { type } = this.props;
        return (
            <div>
                <div
                    className={`errorPopup ${
                        this.state.warningModal ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            {type === 0
                                ? "Da li ste sigurni da želite da izbršete partnera?"
                                : "Da li ste sigurni da želite da izbršete baner?"}
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.handleDeletePage}
                            >
                                Izbriši
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Odustani
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div className="pageTop mb-40">
                    <CustomButton
                        onClick={() => this.showModal("showAddCompetition")}
                    >
                        {type === 0 ? "Dodaj partnera" : "Dodaj baner"}
                    </CustomButton>
                    <AddPartner
                        visible={this.state.showAddCompetition}
                        hideModal={this.hideModal}
                        token={this.props.token}
                        addCompetitionToList={this.addCompetitionToList}
                        type={type}
                    />
                    <EditPartner
                        visible={this.state.showEditCompetition}
                        hideModal={this.hideModal}
                        token={this.props.token}
                        refreshList={this.refreshList}
                        competition={competitionForEdit}
                        type={type}
                    />
                </div>
                <SortableListPartners
                    items={partners}
                    showModal={this.showModal}
                    deleteMenuItem={this.showDeleteModal}
                    token={this.props.token}
                />
            </div>
        );
    }
}

export default PartnersPage;
