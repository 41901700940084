import React, { Component } from "react";
import {
    CustomInput,
    CustomTextArea,
} from "../../../components/Form/Inputs/Inputs";

import { CustomButton } from "../../../components/Buttons/Button";
import { addReservationValidation } from "../../../functions/validateFunctions";
import {
    addImages,
    addReservation,
    getAllCategoriesPage,
    getHours,
} from "../../../functions/postFunctions";
import DatePicker from "react-date-picker";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import { Constants } from "../../../services/constants.service";

class AddReservation extends Component {
    state = {
        organisation: "",
        title: "",
        date: undefined,
        time_from: "",
        time_to: "",
        description: "",
        link: "",
        active: true,
        type: 1,
        errors: [],
        showErrorPopup: false,
        showWarningPopup: false,
        images: null,
        listOfImages: [],
        stepOne: false,
        edit: false,
        completed: 0,
        showUploadPopup: false,
        uploadComplete: false,
        rightSide: false,
        categoryList: [],
        category_id: 4,
        activeLang: 1,
        uploadedImages: [],
        available_times_from: [],
        available_times_to: [],
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Dodaj događaj");
        getAllCategoriesPage().then((res) => {
            if (res.success) {
                const categoryList = res.categories.map((e) => ({
                    value: e.id,
                    label: e.name,
                }));
                this.setState({ categoryList });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.errors != this.state.errors &&
            this.state.errors.length > 0
        ) {
            this.setState({ showErrorPopup: true });
        }
        if (prevState.stepOne !== this.state.stepOne) {
            const item = document.querySelector(".gallery");
            this.navigationMove(item, "gallery");
        }
        // if (prevState.date !== this.state.date) {
        //   this.handleDateChange(this.state.date);
        // }
        if (prevState.time_from !== this.state.time_from) {
            this.handleTimeChange(this.state.time_from);
        }
    }

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleFileInput = (event, temp) => {
        this.setState({ images: [...event] }, () => {
            if (temp) {
                this.handleAddImages();
            }
        });
    };

    removePopup = () => {
        this.setState({ showErrorPopup: false, showWarningPopup: false });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            organisation,
            title,
            date,
            time_from,
            time_to,
            description,
            link,
            active,
            type,
        } = this.state;
        const data = {
            organisation,
            title,
            date,
            time_from,
            time_to,
            description,
            link,
            active,
            type,
        };
        const errors = addReservationValidation(data);
        if (errors.length === 0) {
            this.createPage();
        } else {
            this.setState({ errors });
        }
    };

    handleAddImages = () => {
        const { images } = this.state;
        const data = new FormData();
        if (images.length > 0) {
            images.forEach((e) => {
                data.append("images[]", e);
            });
        }
        data.append("page_id", 0);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        addImages(data, this.props.token, config).then((res) => {
            if (res.success) {
                this.setState(({ uploadedImages }) => ({
                    uploadedImages: [...uploadedImages, ...res.images],
                    images: null,
                }));
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    createPage = () => {
        const {
            organisation,
            title,
            date,
            time_from,
            time_to,
            description,
            link,
            active,
            type,
        } = this.state;
        const data = {
            organisation,
            title,
            date,
            time_from,
            time_to,
            description,
            link,
            active,
            type,
        };
        addReservation(data, this.props.token).then((res) => {
            if (res.success) {
                this.props.history.push("/dogadjaji");
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    underlineRef = React.createRef();

    navigationMove = (item, active) => {
        item.parentElement.querySelectorAll(".link").forEach((e) => {
            e.classList.remove("text-color-primary");
            e.classList.remove("text-color-font-l");
        });
        item.classList.add("text-color-primary");
        this.underlineRef.current.style.transform = `translateX(${item.offsetLeft}px)`;
        this.setState({ active });
    };

    toggleRightSideContent = () => {
        this.setState(({ rightSide }) => ({
            rightSide: !rightSide,
        }));
    };
    setActiveLang = (activeLang) => {
        this.setState({ activeLang });
    };

    copyLink = (link) => {
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    };

    isInt = (time) => {
        return time % 1 === 0;
    };

    handleTimeChange = (time) => {
        const vrijemeOd = time;
        this.setState(
            {
                available_times_to: [],
            },
            () => {
                let vrijemeDoSelect = [];
                const availableHoursTo = this.state.available_times_from.map(
                    (e) => e.value
                );
                for (let i = 0; i < availableHoursTo.length; i++) {
                    if (
                        !availableHoursTo[i] &&
                        availableHoursTo[i - 1] > vrijemeOd
                    )
                        break;
                    if (availableHoursTo[i] > parseFloat(vrijemeOd)) {
                        const timeToShow = this.isInt(availableHoursTo[i])
                            ? availableHoursTo[i]
                            : Math.floor(availableHoursTo[i]) + ":30";
                        vrijemeDoSelect.push({
                            value: availableHoursTo[i],
                            label: `${timeToShow}h`,
                        });
                    }
                }
                this.setState({ available_times_to: vrijemeDoSelect });
            }
        );
    };

    // handleDateChange = date => {
    //   const datum = date;
    //   this.setState({ available_times_to: [], available_times_from: [] }, () => {
    //     const potentialHoursStart = [
    //       8,
    //       8.5,
    //       9,
    //       9.5,
    //       10,
    //       10.5,
    //       11,
    //       11.5,
    //       12,
    //       12.5,
    //       13,
    //       13.5,
    //       14,
    //       14.5,
    //       15,
    //       15.5,
    //       16,
    //       16.5,
    //       17
    //     ];
    //     const potentialHoursEnd = [
    //       9,
    //       9.5,
    //       10,
    //       10.5,
    //       11,
    //       11.5,
    //       12,
    //       12.5,
    //       13,
    //       13.5,
    //       14,
    //       14.5,
    //       15,
    //       15.5,
    //       16,
    //       16.5,
    //       17,
    //       17.5,
    //       18
    //     ];
    //     let vrijemeOdSelect = [];
    //     getHours(datum).then(res => {
    //       if (res.success && res.reservations.length > 0) {
    //         for (let i = 0; i < potentialHoursStart.length; i++) {
    //           for (let j = 0; j < res.reservations.length; j++) {
    //             if (potentialHoursStart[i] === res.reservations[j].time_from) {
    //               potentialHoursStart[i] = null;
    //             }
    //             if (
    //               potentialHoursStart[i] > res.reservations[j].time_from &&
    //               potentialHoursStart[i] < res.reservations[j].time_to
    //             ) {
    //               potentialHoursStart[i] = null;
    //             }

    //             if (
    //               potentialHoursEnd[i] > res.reservations[j].time_from &&
    //               potentialHoursEnd[i] <= res.reservations[j].time_to
    //             ) {
    //               potentialHoursEnd[i] = null;
    //             }
    //             if (
    //               potentialHoursEnd[i] > res.reservations[j].time_from &&
    //               potentialHoursEnd[i] < res.reservations[j].time_to
    //             ) {
    //               potentialHoursEnd[i] = null;
    //             }
    //           }
    //         }
    //       }
    //       // availableHoursTo = potentialHoursEnd;
    //       for (let l = 0; l < potentialHoursStart.length; l++) {
    //         if (potentialHoursStart[l]) {
    //           const timeToShow = this.isInt(potentialHoursStart[l])
    //             ? potentialHoursStart[l]
    //             : Math.floor(potentialHoursStart[l]) + ":30";
    //           vrijemeOdSelect.push({
    //             value: potentialHoursStart[l],
    //             label: `${timeToShow}h`
    //           });
    //         }
    //       }
    //       this.setState({ available_times_from: vrijemeOdSelect });
    //     });
    //   });
    // };

    renderForm = (num, categoryList) => {
        let server = Constants.SERVER;

        return (
            <div className="langWrapper">
                <div className="row mt-50">
                    <div className="col-lg-4">
                        <CustomInput
                            label="Organizator"
                            value={this.state.organisation}
                            handleChange={this.handleInput}
                            name="organisation"
                            index={num - 1}
                        />
                    </div>{" "}
                    <div className="col-lg-4">
                        <CustomInput
                            label="Naslov"
                            value={this.state.title}
                            handleChange={this.handleInput}
                            name="title"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-4">
                        <DatePicker
                            onChange={(e) =>
                                this.handleInput("date", e, num - 1)
                            }
                            value={this.state.date}
                            locale={"bs-BS"}
                            minDate={new Date()}
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-lg-4">
                        <CustomInput
                            label="Link"
                            value={this.state.link}
                            handleChange={this.handleInput}
                            name="link"
                        />
                    </div>
                    <div className="col-lg-4">
                        <CustomInput
                            label="Vrijeme od"
                            value={this.state.time_from}
                            handleChange={this.handleInput}
                            name="time_from"
                        />
                    </div>
                    <div className="col-lg-4">
                        <CustomInput
                            label="Vrijeme do"
                            value={this.state.time_to}
                            handleChange={this.handleInput}
                            name="time_to"
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-12">
                        <CustomTextArea
                            label="Opis"
                            value={this.state.description}
                            handleChange={this.handleInput}
                            name="description"
                            className="textArea"
                            index={num - 1}
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-12 text-right">
                        {/*<CustomButton onClick={this.toggleRightSideContent}>*/}
                        {/*  {!this.state.rightSide*/}
                        {/*    ? "Dodaj drugu kolonu"*/}
                        {/*    : "Ukloni drugu kolonu"}*/}
                        {/*</CustomButton>*/}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { categoryList } = this.state;
        return (
            <div className="wrapper">
                <div
                    className={`errorPopup ${
                        this.state.showWarningPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            Nijedna fotografija nije selektovana. Ukoliko
                            nastavite default-na cover fotografija će biti
                            korištena
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.createPage}
                            >
                                Nastavi
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Zatvori
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div
                    className={`errorPopup ${
                        this.state.showUploadPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                            {!this.state.uploadComplete
                                ? "Upload u toku"
                                : "Optimizacija u toku"}
                        </h3>
                        {!this.state.uploadComplete ? (
                            <div className="uploadBar d-flex align-items-center p-r">
                                <div className="barWrapper">
                                    <span
                                        className="bar d-flex align-items-center justify-content-center"
                                        style={{
                                            width: `${this.state.completed}%`,
                                        }}
                                    />
                                </div>
                                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                                    {this.state.completed}%
                                </span>
                            </div>
                        ) : (
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="contentWrapper">
                    <div
                        className={` errorPopup ${
                            this.state.showErrorPopup ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                            <h3 className="f-s-18 f-w-4 uppercase text-center">
                                Problemi prilikom kreiranja stranice
                            </h3>
                            <ul className="mt-30">
                                {this.state.errors.map((e, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className="f-s-16 text-center"
                                        >
                                            {e}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="button text-center mt-30">
                                <CustomButton onClick={this.removePopup}>
                                    Zatvori
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div
                            className="contentWrapper"
                            style={{
                                display:
                                    this.state.activeLang === 1
                                        ? "block"
                                        : "none",
                            }}
                        >
                            {this.renderForm(
                                this.state.activeLang,
                                categoryList
                            )}
                        </div>
                        <div
                            className="contentWrapper"
                            style={{
                                display:
                                    this.state.activeLang === 2
                                        ? "block"
                                        : "none",
                            }}
                        >
                            {this.renderForm(
                                this.state.activeLang,
                                categoryList
                            )}
                        </div>
                    </form>
                </div>
                <div className="row mt-30">
                    <div className="col-12 d-flex justify-content-end">
                        <CustomButton onClick={this.handleSubmit}>
                            Kreiraj događaj
                        </CustomButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddReservation;
