import React, { Component } from "react";
import {
  changeReservationStatus,
  deleteOrder,
  getAllOrders,
  getAllProducts
} from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import { dateLocalisationShort } from "../../functions/functions";

class OrdersPage extends Component {
  state = {
    theads: [
      "Organizacija",
      "Opis",
      "Email",
      "Telefon",
      "Datum",
      "Vrijeme od",
      "Vrijeme do",
      "Aktivan",
      "Akcije"
    ],
    orders: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Najave");

    getAllOrders().then(res => {
      if (res.success) {
        this.setState({ orders: res.reservations });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
  }

  handleDeletePage = id => {
    const { orders } = this.state;
    const newOrders = orders.filter(e => e.id !== id);
    deleteOrder(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ orders: newOrders });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllProducts(20, 0, currentCategory.value).then(res => {
      if (res.success) {
        this.setState({
          products: res.products,
          page: 1,
          removeLoadMore: res.products.length < 20
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(20, page * 20, currentCategory.value).then(res => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: [...products, ...res.products],
          page: page + 1,
          removeLoadMore: res.products.length < 20
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleStatusChange = (value, index) => {
    this.setState(
      ({ orders }) => ({
        orders: [
          ...orders.slice(0, index),
          {
            ...orders[index],
            active: !orders[index].active
          },
          ...orders.slice(index + 1)
        ]
      }),
      () => {
        this.changeStatus(
          this.state.orders[index].id,
          this.state.orders[index].active
        );
      }
    );
  };

  changeStatus = (id, active) => {
    changeReservationStatus(id, active, this.props.token).then(res =>
      console.log(res)
    );
  };

  render() {
    const { theads, orders } = this.state;
    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-9">
              <LinkButton href="dodaj-dogadjaj">Dodaj događaj</LinkButton>
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {orders.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <span className="f-s-16">{e.organisation}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.description}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.email}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.phone}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">
                    {dateLocalisationShort(e.date)}
                  </span>
                </td>
                <td className="td px-10" style={{ minWidth: "120px" }}>
                  <span className="f-s-16">{e.time_from}h</span>
                </td>
                <td className="td px-10" style={{ minWidth: "120px" }}>
                  <span className="f-s-16">{e.time_to}h</span>
                </td>
                <td className="td px-10" style={{ minWidth: "120px" }}>
                  <span className="f-s-16">
                    <input
                      type="checkbox"
                      checked={e.active}
                      onChange={e =>
                        this.handleStatusChange(e.target.checked, index)
                      }
                    />
                  </span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <LinkButton
                    href={`izmjeni-dogadjaj/${e.id}`}
                    className="mr-20"
                  >
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
        {!this.state.removeLoadMore ? (
          <div className="wrapper mt-40 d-flex justify-content-center">
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default OrdersPage;
