import React, { Component } from "react";
import {
    addImages,
    addMember,
    addTestimonial,
    getAllCategoriesPage,
} from "../../../functions/postFunctions";

import { CustomButton } from "../../../components/Buttons/Button";
import {
    CustomInput,
    CustomTextArea,
} from "../../../components/Form/Inputs/Inputs";
import DatePicker from "react-date-picker";
import {
    addMemberValidation,
    addTestimonialVerification,
} from "../../../functions/validateFunctions";
import { dateLocalisationShort } from "../../../functions/functions";

class AddTestimonial extends Component {
    state = {
        name: "",
        testimonial: "",
        testimonial_en: "",
        company: "",
        logo: null,
        errors: [],
        showErrorPopup: false,
        showWarningPopup: false,
        listOfImages: [],
        edit: false,
        completed: 0,
        showUploadPopup: false,
        uploadComplete: false,
        rightSide: false,
        categoryList: [],
        category_id: 4,
        activeLang: 1,
        uploadedImages: [],
        available_times_from: [],
        available_times_to: [],
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Dodaj testimonial");
        getAllCategoriesPage().then((res) => {
            if (res.success) {
                const categoryList = res.categories.map((e) => ({
                    value: e.id,
                    label: e.name,
                }));
                this.setState({ categoryList });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.errors != this.state.errors &&
            this.state.errors.length > 0
        ) {
            this.setState({ showErrorPopup: true });
        }
        if (prevState.stepOne !== this.state.stepOne) {
            const item = document.querySelector(".gallery");
            this.navigationMove(item, "gallery");
        }
        if (prevState.time_from !== this.state.time_from) {
            this.handleTimeChange(this.state.time_from);
        }
    }

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleFileInput = (event, name) => {
        this.setState({ [name]: event[0] });
    };

    removePopup = () => {
        this.setState({ showErrorPopup: false, showWarningPopup: false });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { name, company, testimonial, testimonial_en, logo } = this.state;
        const data = {
            name,
            company,
            testimonial,
            testimonial_en,
            image: logo,
        };
        const errors = addTestimonialVerification(data);

        if (errors.length === 0) {
            this.createPage(data);
        } else {
            this.setState({ errors });
        }
    };

    handleAddImages = () => {
        const { logo } = this.state;
        const data = new FormData();
        if (logo.length > 0) {
            logo.forEach((e) => {
                data.append("logo[]", e);
            });
        }
        data.append("page_id", 0);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        addImages(data, this.props.token, config).then((res) => {
            if (res.success) {
                this.setState(({ uploadedImages }) => ({
                    uploadedImages: [...uploadedImages, ...res.images],
                    images: null,
                }));
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    createPage = (data) => {
        const formData = new FormData();
        const creation_date = data.creation_date;
        data.creation_date = dateLocalisationShort(
            creation_date,
            "en-GB"
        ).replaceAll("/", ".");
        Object.keys(data).forEach((e) => {
            formData.append(e, data[e]);
        });

        this.setState({ showUploadPopup: true });
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };

        addTestimonial(formData, this.props.token, config).then((res) => {
            if (res.success) {
                this.props.history.push("/testimonials");
            } else {
                this.setState({ showErrorPopup: true });
            }
        });
    };

    renderForm = (num) => {
        return (
            <div className="langWrapper">
                <div className="row mt-50">
                    <div className="col-lg-6">
                        <CustomInput
                            label="Ime"
                            value={this.state.name}
                            handleChange={this.handleInput}
                            name="name"
                            index={num - 1}
                        />
                    </div>
                    <div className="col-lg-6">
                        <CustomInput
                            label="Kompanija"
                            value={this.state.company}
                            handleChange={this.handleInput}
                            name="company"
                            index={num - 1}
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-lg-6">
                        <CustomTextArea
                            label="Testimonial CG"
                            value={this.state.testimonial}
                            handleChange={this.handleInput}
                            name="testimonial"
                            index={num - 1}
                            className="textArea"
                        />
                    </div>
                    <div className="col-lg-6">
                        <CustomTextArea
                            label="Testimonial EN"
                            value={this.state.testimonial_en}
                            handleChange={this.handleInput}
                            name="testimonial_en"
                            index={num - 1}
                            className="textArea"
                        />
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-lg-4">
                        <CustomInput
                            label="Fotografija"
                            value={this.state.logo}
                            handleChange={this.handleFileInput}
                            name="logo"
                            index={num - 1}
                            type="file"
                            temp="logo"
                            img
                            subMessage="(512x512)"
                        />
                        {this.state.logo && (
                            <div>
                                <h4 className="f-s-18 text-color-secondary">
                                    Odabrana fotografija:
                                </h4>
                                <p className="f-s-14 text-color-primary">
                                    {this.state.logo.name}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="wrapper">
                <div
                    className={`errorPopup ${
                        this.state.showWarningPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            Nijedna fotografija nije selektovana. Ukoliko
                            nastavite default-na cover fotografija će biti
                            korištena
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.createPage}
                            >
                                Nastavi
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Zatvori
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div
                    className={`errorPopup ${
                        this.state.showUploadPopup ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                            {!this.state.uploadComplete
                                ? "Upload u toku"
                                : "Optimizacija u toku"}
                        </h3>
                        {!this.state.uploadComplete ? (
                            <div className="uploadBar d-flex align-items-center p-r">
                                <div className="barWrapper">
                                    <span
                                        className="bar d-flex align-items-center justify-content-center"
                                        style={{
                                            width: `${this.state.completed}%`,
                                        }}
                                    />
                                </div>
                                <span className="outerProgress f-s-16 center-a-b text-color-primary">
                                    {this.state.completed}%
                                </span>
                            </div>
                        ) : (
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="contentWrapper">
                    <div
                        className={` errorPopup ${
                            this.state.showErrorPopup ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                            <h3 className="f-s-18 f-w-4 uppercase text-center">
                                Problemi prilikom kreiranja člana
                            </h3>
                            <ul className="mt-30">
                                {this.state.errors.map((e, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className="f-s-16 text-center"
                                        >
                                            {e}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="button text-center mt-30">
                                <CustomButton onClick={this.removePopup}>
                                    Zatvori
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div
                            className="contentWrapper"
                            style={{
                                display:
                                    this.state.activeLang === 1
                                        ? "block"
                                        : "none",
                            }}
                        >
                            {this.renderForm(this.state.activeLang)}
                        </div>
                        <div
                            className="contentWrapper"
                            style={{
                                display:
                                    this.state.activeLang === 2
                                        ? "block"
                                        : "none",
                            }}
                        >
                            {this.renderForm(this.state.activeLang)}
                        </div>
                    </form>
                </div>
                <div className="row mt-30">
                    <div className="col-12 d-flex justify-content-end">
                        <CustomButton onClick={this.handleSubmit}>
                            Kreiraj testimonial
                        </CustomButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddTestimonial;
