import { CustomButton, LinkButton } from "../Buttons/Button";
import React, { Component } from "react";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";
import { sortMenus, sortPartners } from "../../functions/postFunctions";

import { ListIcon } from "../icons/icons";
import { Constants } from "../../services/constants.service";
class SortableListPartners extends Component {
    state = {
        items: [],
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(
            {
                items: arrayMove(this.state.items, oldIndex, newIndex),
            },
            () => this.handleSortMenus()
        );
    };

    componentDidMount() {
        this.setState({ items: this.props.items });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.setState({ items: this.props.items });
        }
    }

    handleSortMenus = () => {
        const type = this.props.type || 0;
        sortPartners(this.state.items, type, this.props.token).then(
            (res) => null
        );
    };

    prefixValidator = (string) => {
        return (
            "https://" + string.replace("http://", "").replace("https://", "")
        );
    };

    render() {
        let server = Constants.SERVER;

        // if (process.env.NODE_ENV === "development") {
        //     server = "http://127.0.0.1:8000";
        // } else {
        //     server = Constants.SERVER;
        // }
        const SortableItem = SortableElement(({ item, index }) => (
            <li
                className={`level-${item.level} mt-20`}
                style={{
                    paddingLeft: `${item.level * 20}px`,
                    listStyle: "none",
                    borderBottom: "1px solid #edf0f2",
                }}
                key={index}
            >
                <span className="f-s-16 d-flex align-items-center justify-content-between">
                    <span className="left d-flex align-items-center">
                        <ListIcon className="fill mr-10" />
                        {item.image !== "" ? (
                            <div
                                className="pa-20 d-flex justify-content-center align-items-center"
                                style={{ minWidth: "200px" }}
                            >
                                <img
                                    src={`${server}/${item.image}`}
                                    alt={""}
                                    style={{ maxHeight: "80px" }}
                                />
                            </div>
                        ) : (
                            <span className="f-s-24 text-color-white">X</span>
                        )}
                        {item.type === 1 && (
                            <span className="ml-20 ">
                                <b className="mr-5">Naziv:</b> {item.name}
                            </span>
                        )}
                        <span className="ml-20 ">
                            <b className="mr-5">Link:</b>
                            {this.prefixValidator(item.link)}
                        </span>
                    </span>
                    <span className="right d-flex align-items-center">
                        <CustomButton
                            className="mr-20"
                            onClick={() =>
                                this.props.showModal(
                                    "showEditCompetition",
                                    item.id
                                )
                            }
                        >
                            Izmijeni
                        </CustomButton>
                        <CustomButton
                            className="red"
                            onClick={() => this.props.deleteMenuItem(item.id)}
                        >
                            Izbriši
                        </CustomButton>
                    </span>
                </span>
            </li>
        ));

        const SortableList = SortableContainer(({ items }) => {
            return (
                <ul className="pt-40">
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            <SortableItem index={index} item={item} />
                        </React.Fragment>
                    ))}
                </ul>
            );
        });

        return (
            <SortableList
                items={this.state.items}
                onSortEnd={this.onSortEnd}
                axis="y"
                helperClass="SortableHelper"
                key={this.props.key}
            />
        );
    }
}

export default SortableListPartners;
