import React, { Component } from "react";
import { deleteMenu, getAllMenus } from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import EditCompetition from "../../components/EditCompetition/EditCompetition";
import AddMenu from "../../components/AddMenu/AddMenu";
import EditMenu from "../../components/EditMenu/EditMenu";
import { Constants } from "../../services/constants.service";

class MenusPage extends Component {
    state = {
        theads: ["Naziv", "Pozicija", "Akcije"],
        menus: [],
        positions: [
            {
                value: 0,
                label: "Glavni Meni",
            },
            {
                value: 1,
                label: "Meni na vrhu",
            },
            {
                value: 2,
                label: "Lijevi meni",
            },
            {
                value: 3,
                label: "Desni Meni",
            },
            {
                value: 4,
                label: "Meni u podnožju - Lijevo",
            },
            {
                value: 5,
                label: "Meni u podnožju - Desno",
            },
        ],
        warningModal: false,
        menuToDelete: null,
        menuForEdit: null,
    };

    removePopup = () => {
        this.setState({ warningModal: false });
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Meniji");
        getAllMenus().then((res) => {
            this.setState({
                menus: res.menus,
            });
        });
    }

    handleDeletePage = (id) => {
        deleteMenu(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ menus: res.menus, warningModal: false });
            }
        });
    };

    showModal = (name, id) => {
        let menu = null;
        if (id) {
            const { menus } = this.state;
            menu = menus.find((e) => e.id === id);
        }
        this.setState({ [name]: true, menuForEdit: menu });
    };

    hideModal = () => {
        this.setState({
            showAddCompetition: false,
            showEditCompetition: false,
            menuForEdit: null,
        });
    };

    addMenusToList = (menus) => {
        this.setState({
            menus: menus,
            showAddCompetition: false,
            showEditCompetition: false,
        });
    };

    refreshList = () => {
        getAllMenus().then((res) => {
            this.setState({
                menus: res.menus,
            });
        });
    };

    showWarningModal = (id) => {
        this.setState({ menuToDelete: id, warningModal: true });
    };

    render() {
        const { theads, menus, positions, menuForEdit } = this.state;
        const server = Constants.SERVER;

        return (
            <div>
                <div className="pageTop mb-40">
                    <CustomButton
                        onClick={() => this.showModal("showAddCompetition")}
                    >
                        Dodaj meni
                    </CustomButton>
                    <AddMenu
                        visible={this.state.showAddCompetition}
                        hideModal={this.hideModal}
                        token={this.props.token}
                        addMenusToList={this.addMenusToList}
                        positionList={positions}
                    />
                    <EditMenu
                        visible={this.state.showEditCompetition}
                        hideModal={this.hideModal}
                        token={this.props.token}
                        addMenusToList={this.addMenusToList}
                        menuForEdit={menuForEdit}
                        positions={this.state.positions}
                    />
                    <div
                        className={`errorPopup ${
                            this.state.warningModal ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                                Upozorenje
                            </h3>
                            <h5 className="f-s-16 f-w-4 uppercase text-center">
                                Da li ste sigurni da želite da izbrišete meni?
                            </h5>
                            <div className="button text-center mt-30">
                                <CustomButton
                                    className="mr-10 red"
                                    onClick={() =>
                                        this.handleDeletePage(
                                            this.state.menuToDelete
                                        )
                                    }
                                >
                                    Izbriši
                                </CustomButton>
                                <CustomButton onClick={this.removePopup}>
                                    Odustani
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
                <Table theads={theads}>
                    {menus.map((e, index) => {
                        return (
                            <tr key={index}>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.name}</span>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        {positions.length > 0 &&
                                            positions.find(
                                                (el) => el.value === e.position
                                            ).label}
                                    </span>
                                </td>
                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    <LinkButton
                                        href={`meniji/${e.id}`}
                                        className="mr-20 green"
                                    >
                                        Stavke menija
                                    </LinkButton>
                                    <CustomButton
                                        onClick={() =>
                                            this.showModal(
                                                "showEditCompetition",
                                                e.id
                                            )
                                        }
                                        className="mr-20"
                                    >
                                        Izmjeni
                                    </CustomButton>

                                    <CustomButton
                                        onClick={() =>
                                            this.showWarningModal(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
            </div>
        );
    }
}

export default MenusPage;
