import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import { deletePage, getAllPages } from "../../functions/postFunctions";

import Table from "../../components/Table/Table";
import { dateLocalisation } from "../../functions/functions";
import { Constants } from "../../services/constants.service";

class NewsPage extends Component {
    state = {
        theads: ["Cover", "Naslov", "Link", "Novost ažurirana", "Akcije"],
        pages: [],
        removeLoadMore: true,
        page: 1,
        categoryList: [],
        currentCategory: 1,
        warningModal: false,
        deletionID: 0,
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Novosti");
        this.loadNews();
    }
    handleDeletePage = () => {
        const id = this.state.deletionID;

        const { pages } = this.state;
        const newPages = pages.filter((e) => e.id !== id);
        this.setState({ warningModal: false, deletionID: 0 });

        deletePage(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ pages: newPages });
            }
        });
    };
    showDeleteModal = (id) => {
        this.setState({ warningModal: true, deletionID: id });
    };

    removePopup = () => {
        this.setState({ warningModal: false, deletionID: 0 });
    };
    loadNews = () => {
        getAllPages(1, 20, 0, 1).then((res) => {
            if (res.success) {
                this.setState({
                    pages: res.pages,
                    page: 1,
                    removeLoadMore: res.pages.length < 20,
                });
            }
        });
    };

    loadMoreNews = () => {
        const { page, currentCategory } = this.state;
        getAllPages(1, 20, page * 20, currentCategory.value).then((res) => {
            if (res.success) {
                this.setState(({ pages, page }) => ({
                    pages: [...pages, ...res.pages],
                    page: page + 1,
                    removeLoadMore: res.pages.length < 20,
                }));
            }
        });
    };

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    render() {
        const { theads, pages, categoryList, currentCategory } = this.state;
        const server = Constants.SERVER;

        return (
            <div>
                <div
                    className={`errorPopup ${
                        this.state.warningModal ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            Da li ste sigurni da želite da izbrišete novost?
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.handleDeletePage}
                            >
                                Izbriši
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Odustani
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div className="pageTop mb-40">
                    <div className="row">
                        <div className="col-md-9">
                            <LinkButton href="dodaj-novost">
                                Dodaj novost
                            </LinkButton>
                        </div>
                    </div>
                </div>
                <Table theads={theads}>
                    {pages.map((e, index) => {
                        return (
                            <tr key={index}>
                                <td className="td px-10">
                                    <img
                                        src={`${server}/${e.cover}`}
                                        alt={e.slug}
                                        style={{ maxHeight: "50px" }}
                                    />
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.title}</span>
                                </td>
                                <td className="td px-10">
                                    <a
                                        href={`https://www.economicteam.me/clanak/${e.slug}`}
                                        className="f-s-16 transition-1 py-5 br-r-5 text-color-primary regular-link"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                    >
                                        Pogledaj stranicu
                                    </a>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        {dateLocalisation(e.updated_at)}
                                    </span>
                                </td>
                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    <LinkButton
                                        href={`izmjeni-novost/${e.id}`}
                                        className="mr-20"
                                    >
                                        Izmijeni
                                    </LinkButton>
                                    <CustomButton
                                        onClick={() =>
                                            this.showDeleteModal(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                {!this.state.removeLoadMore ? (
                    <div className="wrapper mt-40 d-flex justify-content-center">
                        <CustomButton onClick={this.loadMoreNews}>
                            Učitaj još
                        </CustomButton>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default NewsPage;
