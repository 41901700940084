import {
    AnalyticsIcon,
    BannersIcon,
    LibraryIcon,
    MediaIcon,
    NewsIcon,
    PlayerIcon,
    SponsorsIcon,
    StaffIcon,
} from "../icons/icons";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";

import Logo from "./Logo/Logo";

class Navbar extends Component {
    state = {
        playersDropdown: false,
        pagesDropdown: false,
        productDropdown: false,
    };
    linkHover = () => {
        const elements = document.getElementById("nav").querySelectorAll("a");

        for (let i = 0; i < elements.length; i++) {
            elements[i].onmouseover = function (e) {
                elements[i].setAttribute("data-hover", "hoverActive");
            };
            elements[i].onmouseout = function () {
                elements[i].setAttribute("data-hover", "hoverLeft");
            };
        }
    };
    componentDidMount() {
        this.linkHover();
    }

    toggleDropdown = (e, dropdown) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState((prevState) => ({
            [dropdown]: !prevState[dropdown],
        }));
    };

    closeDropdown = () => {
        this.setState({
            playersDropdown: false,
            pagesDropdown: false,
        });
    };

    render() {
        const { user } = this.props;
        if (!user) {
            return (
                <>
                    <nav id="nav">
                        <div className="logoContainer p-r d-flex justify-content-center align-items-center">
                            <Link
                                to="/"
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Logo className="center-r-v pl-30" />
                            </Link>
                        </div>
                        <div className="navContainer">
                            <ul className="f-s-12" onClick={this.closeDropdown}>
                                <li>
                                    <NavLink
                                        exact={true}
                                        to="/"
                                        className="p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <NewsIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Novosti
                                        </span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        exact={true}
                                        to="/testimonials"
                                        className="p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <StaffIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Testimonials
                                        </span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        exact={true}
                                        to="/dokumenti"
                                        className="p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <LibraryIcon className="fill" />
                                        </span>
                                        <span className=" title d-inline-block">
                                            Dokumenti
                                        </span>
                                    </NavLink>
                                </li>
                                {/* <li className="subMenu">
                                    <a
                                        className="subMenuToggle p-r"
                                        onClick={(e) =>
                                            this.toggleDropdown(
                                                e,
                                                "playersDropdown"
                                            )
                                        }
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <LibraryIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Biblioteka
                                        </span>
                                    </a>
                                    <div
                                        style={{
                                            height: this.state.playersDropdown
                                                ? "100px"
                                                : 0,
                                        }}
                                        className="subMenuDropdown"
                                        id="playersDropdown"
                                    >
                                        <NavLink
                                            exact={true}
                                            to="/dokumenti"
                                            className="p-r"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <span className="pl-15 title d-inline-block">
                                                Dokumenti
                                            </span>
                                        </NavLink>
                                        <NavLink
                                            exact={true}
                                            to="/publikacije"
                                            className="p-r"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <span className="pl-15 title d-inline-block">
                                                Publikacije
                                            </span>
                                        </NavLink>
                                    </div>
                                </li> */}

                                {/* <li>
                                    <NavLink
                                        exact={true}
                                        to="/baneri"
                                        className="p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <BannersIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Baneri
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        exact={true}
                                        to="/partneri"
                                        className="p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <SponsorsIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Partneri
                                        </span>
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        exact={true}
                                        to="/zaposleni"
                                        className=" p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <PlayerIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Zaposleni
                                        </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <li>
                                        <a
                                            href="https://analytics.google.com/analytics/web/"
                                            className="p-r"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <span className="icon d-inline-block mr-10">
                                                <AnalyticsIcon className="fill" />
                                            </span>
                                            <span className="title d-inline-block">
                                                Google Analytics
                                            </span>
                                        </a>
                                    </li>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </>
            );
        } else {
            return (
                <>
                    <nav id="nav">
                        <div className="logoContainer p-r d-flex justify-content-center align-items-center">
                            <Link
                                to="/"
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Logo className="center-r-v pl-30" />
                            </Link>
                        </div>
                        <div className="navContainer">
                            <ul className="f-s-12" onClick={this.closeDropdown}>
                                <li>
                                    <NavLink
                                        exact={true}
                                        to={`/${this.props.customer}`}
                                        className="p-r"
                                    >
                                        <span className="icon d-inline-block mr-10">
                                            <StaffIcon className="fill" />
                                        </span>
                                        <span className="title d-inline-block">
                                            Članovi
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </>
            );
        }
    }
}

export default Navbar;
