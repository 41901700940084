import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
    changeReservationStatus,
    deleteMember,
    deleteOrder,
    deleteTestimonial,
    getAllMembers,
    getAllOrders,
    getAllProducts,
    getAllTestimonials,
} from "../../functions/postFunctions";

import Table from "../../components/Table/Table";
import { dateLocalisationShort } from "../../functions/functions";
import { Constants } from "../../services/constants.service";

class TestimonialsPage extends Component {
    state = {
        theads: ["Slika", "Ime", "Firma", "Akcije"],
        orders: [],
        removeLoadMore: true,
        page: 1,
        categoryList: [],
        currentCategory: null,
        warningModal: false,
        deletionID: 0,
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Testimonials");

        getAllTestimonials().then((res) => {
            if (res.success) {
                this.setState({ orders: res.testimonials });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.currentCategory !== this.state.currentCategory &&
            this.state.currentCategory
        ) {
            this.loadNews();
        }
    }

    handleDeletePage = () => {
        const id = this.state.deletionID;
        const { orders } = this.state;
        const newOrders = orders.filter((e) => e.id !== id);
        this.setState({ warningModal: false, deletionID: 0 });
        deleteTestimonial(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ orders: newOrders });
            }
        });
    };

    loadNews = () => {
        const { currentCategory } = this.state;
        getAllProducts(20, 0, currentCategory.value).then((res) => {
            if (res.success) {
                this.setState({
                    products: res.products,
                    page: 1,
                    removeLoadMore: res.products.length < 20,
                });
            }
        });
    };

    loadMoreNews = () => {
        const { page, currentCategory } = this.state;
        getAllProducts(20, page * 20, currentCategory.value).then((res) => {
            if (res.success) {
                this.setState(({ products, page }) => ({
                    products: [...products, ...res.products],
                    page: page + 1,
                    removeLoadMore: res.products.length < 20,
                }));
            }
        });
    };

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleStatusChange = (value, index) => {
        this.setState(
            ({ orders }) => ({
                orders: [
                    ...orders.slice(0, index),
                    {
                        ...orders[index],
                        active: !orders[index].active,
                    },
                    ...orders.slice(index + 1),
                ],
            }),
            () => {
                this.changeStatus(
                    this.state.orders[index].id,
                    this.state.orders[index].active
                );
            }
        );
    };

    showDeleteModal = (id) => {
        this.setState({ warningModal: true, deletionID: id });
    };

    removePopup = () => {
        this.setState({ warningModal: false, deletionID: 0 });
    };

    render() {
        const { theads, orders } = this.state;
        const server = Constants.SERVER;

        return (
            <div>
                <div
                    className={`errorPopup ${
                        this.state.warningModal ? "popupShown" : ""
                    }`}
                >
                    <div className="content py-20 px-10 d-flex justify-content-between flex-column">
                        <h3 className="f-s-22 f-w-4 uppercase text-center text-color-red">
                            Upozorenje
                        </h3>
                        <h5 className="f-s-16 f-w-4 uppercase text-center">
                            Da li ste sigurni da želite da izbrišete
                            testimonial?
                        </h5>
                        <div className="button text-center mt-30">
                            <CustomButton
                                className="mr-10 red"
                                onClick={this.handleDeletePage}
                            >
                                Izbriši
                            </CustomButton>
                            <CustomButton onClick={this.removePopup}>
                                Odustani
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div className="pageTop mb-40">
                    <div className="row">
                        <div className="col-md-9">
                            <LinkButton href="dodaj-testimonial">
                                Dodaj Testimonial
                            </LinkButton>
                        </div>
                    </div>
                </div>
                <Table theads={theads}>
                    {orders.map((e, index) => {
                        return (
                            <tr key={index}>
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        <img
                                            style={{ maxWidth: "100px" }}
                                            src={`${server}/${e.image}`}
                                            alt={e.logo}
                                        />
                                    </span>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.name}</span>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.company}</span>
                                </td>
                                {/* <td className="td px-10">
                                    <span className="f-s-16">
                                        {e.testimonial}
                                    </span>
                                </td>{" "}
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        {e.testimonial_en}
                                    </span>
                                </td> */}
                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    <LinkButton
                                        href={`izmijeni-testimonial/${e.id}`}
                                        className="mr-20"
                                    >
                                        Izmijeni
                                    </LinkButton>
                                    <CustomButton
                                        onClick={() =>
                                            this.showDeleteModal(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                {!this.state.removeLoadMore ? (
                    <div className="wrapper mt-40 d-flex justify-content-center">
                        <CustomButton onClick={this.loadMoreNews}>
                            Učitaj još
                        </CustomButton>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default TestimonialsPage;
