import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import {
    CustomInput,
    CustomTextArea,
} from "../../components/Form/Inputs/Inputs";
import React, { Component } from "react";
import {
    addPressClipping,
    deletePressClipping,
    getAllPages,
    getAllPressClippings,
} from "../../functions/postFunctions";
import {
    dateLocalisation,
    dateLocalisationShort,
} from "../../functions/functions";

import DatePicker from "react-date-picker";
import Table from "../../components/Table/Table";
import { Constants } from "../../services/constants.service";

class PressClippingsPage extends Component {
    state = {
        theads: ["Cover", "Naslov", "Datum", "Opis", "Link", "Akcije"],
        pages: [],
        removeLoadMore: true,
        page: 1,
        categoryList: [],
        currentCategory: 1,
        title: "",
        date: new Date(),
        cover: null,
        description: "",
        link: "",
        popupShown: false,
    };

    componentDidMount() {
        this.props.setBreadcrumbs("Press Clippings");
        this.loadNews();
    }
    handleDeletePage = (id) => {
        const { pages } = this.state;
        const newPages = pages.filter((e) => e.id !== id);

        deletePressClipping(id, this.props.token).then((res) => {
            if (res.success) {
                this.setState({ pages: newPages });
            }
        });
    };

    loadNews = () => {
        getAllPressClippings().then((res) => {
            if (res.success) {
                this.setState({
                    pages: res.pressClippings,
                    page: 1,
                    removeLoadMore: res.pressClippings.length < 20,
                });
            }
        });
    };

    loadMoreNews = () => {
        const { page, currentCategory } = this.state;
        getAllPages(1, 20, page * 20, currentCategory.value).then((res) => {
            if (res.success) {
                this.setState(({ pages, page }) => ({
                    pages: [...pages, ...res.pages],
                    page: page + 1,
                    removeLoadMore: res.pages.length < 20,
                }));
            }
        });
    };

    handleInput = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    handleAddAnnouncement = () => {
        const { title, date, cover, description, link } = this.state;

        const data = new FormData();

        const formattedDate = dateLocalisationShort(date, "en-GB").replaceAll(
            "/",
            "."
        );

        data.append("title", title);
        data.append("date", formattedDate);
        data.append("cover", cover);
        data.append("description", description);
        data.append("link", link);

        this.setState({ popupShown: true });
        addPressClipping(data, this.props.token).then((res) => {
            if (res.success) {
                this.setState({
                    pages: res.pressClippings,
                    title: "",
                    date: new Date(),
                    cover: null,
                    description: "",
                    link: "",
                    popupShown: false,
                });
            } else {
                this.setState({ popupShown: false });
            }
        });
    };

    handleFileInput = (event) => {
        this.setState({ cover: event[0] });
    };

    prefixValidator = (string) => {
        return (
            "https://" + string.replace("http://", "").replace("https://", "")
        );
    };

    render() {
        const { theads, pages, categoryList, currentCategory } = this.state;
        const server = Constants.SERVER;

        return (
            <div>
                <div className="wrapper">
                    <div
                        className={`errorPopup ${
                            this.state.popupShown ? "popupShown" : ""
                        }`}
                    >
                        <div className="content py-20 px-10 d-flex justify-content-center flex-column">
                            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                                Kreiranje press clippinga u toku
                            </h3>
                            <div className="optimization loader d-flex justify-content-center align-items-center">
                                <div className="fancy-spinner">
                                    <div className="ring" />
                                    <div className="ring" />
                                    <div className="dot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.popupShown && (
                    <div className="pageTop mb-40">
                        <div className="row">
                            <div className="col-lg-4">
                                <CustomInput
                                    label="Naslov"
                                    value={this.state.title}
                                    handleChange={this.handleInput}
                                    name="title"
                                />
                            </div>
                            <div className="col-lg-4">
                                <CustomInput
                                    label="Link"
                                    value={this.state.link}
                                    handleChange={this.handleInput}
                                    name="link"
                                />
                            </div>
                            <div className="col-lg-4">
                                <DatePicker
                                    onChange={(e) =>
                                        this.handleInput("date", e)
                                    }
                                    value={this.state.date}
                                    locale={"sr-Latn-ME"}
                                />
                            </div>
                        </div>
                        <div className="row mt-30 align-items-center">
                            <div className="col-4">
                                <CustomTextArea
                                    label="Opis"
                                    value={this.state.description}
                                    handleChange={this.handleInput}
                                    name="description"
                                    className="textArea"
                                />
                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-center">
                                <CustomInput
                                    label="Izaberi cover"
                                    value={null}
                                    handleChange={this.handleFileInput}
                                    name="cover"
                                    index={0}
                                    type="file"
                                    className="mt-40"
                                    temp="image"
                                    subMessage="(960x640)"
                                />
                                <div className="galleryWrapper">
                                    {this.state.cover ? (
                                        <div className="selectedImages ml-30 py-10 px-15">
                                            <h3 className="f-s-16 text-color-primary f-w-4">
                                                Fotografija odabrana za upload:
                                            </h3>
                                            <p className="mt-20">
                                                {this.state.cover.name}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <CustomButton
                                    className="green"
                                    onClick={this.handleAddAnnouncement}
                                >
                                    Dodaj Press Clipping
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )}
                <Table theads={theads}>
                    {pages.map((e, index) => {
                        return (
                            <tr key={index}>
                                <td className="td px-10">
                                    <img
                                        src={`${server}/${e.cover}`}
                                        alt={e.cover}
                                        style={{ maxHeight: "50px" }}
                                    />
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">{e.title}</span>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        {dateLocalisationShort(e.date)}
                                    </span>
                                </td>
                                <td className="td px-10">
                                    <span className="f-s-16">
                                        {e.description}
                                    </span>
                                </td>
                                <td className="td px-10">
                                    <a
                                        href={this.prefixValidator(e.link)}
                                        className="f-s-16 transition-1 py-5 br-r-5 text-color-primary regular-link"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                    >
                                        Pogledaj
                                    </a>
                                </td>

                                <td
                                    className="td px-10"
                                    style={{ minWidth: "220px" }}
                                >
                                    {/* <LinkButton href={`izmjeni-vijest/${e.id}`} className="mr-20">
                    Izmjeni
                  </LinkButton> */}
                                    <CustomButton
                                        onClick={() =>
                                            this.handleDeletePage(e.id)
                                        }
                                        className="red"
                                    >
                                        Izbriši
                                    </CustomButton>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
                {!this.state.removeLoadMore ? (
                    <div className="wrapper mt-40 d-flex justify-content-center">
                        <CustomButton onClick={this.loadMoreNews}>
                            Učitaj još
                        </CustomButton>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default PressClippingsPage;
